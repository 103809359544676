var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-menu',{attrs:{"left":"","offset-x":"","nudge-left":"10"},scopedSlots:_vm._u([{key:"activator",fn:function({ on: onMenu }){return [_c('v-tooltip',{attrs:{"bottom":!!_vm.iconStyles,"left":!_vm.iconStyles},scopedSlots:_vm._u([{key:"activator",fn:function({ on: onTooltip }){return [_c('v-btn',_vm._g({staticClass:"btn-fix",style:({
            'background-color': _vm.getButtonBackgroundColor(),
            'margin-top':
              _vm.iconStyles && _vm.iconStyles.marginTop
                ? _vm.iconStyles.marginTop
                : '140px'
          }),attrs:{"id":"basemap-switch","tile":"","absolute":"","top":"","elevation":_vm.iconStyles ? '0' : '2',"right":"","fab":"","small":""},on:{"click":_vm.closeOtherMenu}},{ ...onMenu, ...onTooltip }),[(!_vm.openMenu)?_c('v-icon',{style:({
              'background-color':
                _vm.iconStyles && _vm.iconStyles.backgroundColor
                  ? _vm.iconStyles.backgroundColor
                  : 'initial',
              color:
                _vm.iconStyles && _vm.iconStyles.iconColor
                  ? _vm.iconStyles.iconColor
                  : 'grey darken-3'
            })},[_vm._v("mdi-map")]):_c('v-icon',{attrs:{"color":_vm.iconStyles && _vm.iconStyles.iconColor
                ? _vm.iconStyles.iconColor
                : 'white'}},[_vm._v("mdi-map")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("basemapLayer")))])])]}}]),model:{value:(_vm.openMenu),callback:function ($$v) {_vm.openMenu=$$v},expression:"openMenu"}},[_c('v-list',{style:({
      'margin-top': _vm.iconStyles && _vm.iconStyles.marginTop ? '44px' : ''
    })},[_c('span',{staticClass:"pl-2 font-weight-bold"},[_vm._v(_vm._s(_vm.$t("basemapLayer")))]),_c('v-divider'),_vm._l((_vm.layers),function(item){return _c('v-list-item',{key:item.id,on:{"click":function($event){return _vm.switchBasemap(item.id)}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t(item.id)))])],1)}),_c('v-list-item',[_c('v-list-item-action',[_c('v-checkbox',{on:{"change":_vm.toggleTerrain},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"pl-2"},[_vm._v("Terrain")])]},proxy:true}])})],1)],1)],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }