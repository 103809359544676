<template>
  <v-dialog v-model="dialog" width="600">
    <template v-slot:activator="{ on }">
      <v-btn
        elevation="0"
        tile
        class="pb-1"
        icon
        :title="$t('infobox.infoVS')"
        small
        v-on="on"
      >
        <v-icon class="pa-0" small dense color="grey"
          >mdi-information-outline</v-icon
        >
      </v-btn>
      <!--      <v-icon-->
      <!--        v-on="on"-->
      <!--        :title="$t('infobox.virtualStationInfoTitle')"-->
      <!--      >mdi-information-outline-->
      <!--      </v-icon-->
      <!--      >-->
    </template>

    <v-card>
      <v-card-title class="mb-5">
        {{ $t("infobox.virtualStationInfoTitle") }}
      </v-card-title>
      <v-card-text>
        {{ $t("infobox.virtualStationDescription") }}
        <ul>
          <li>{{ $t("infobox.virtualStationFirstBullet") }}</li>
          <li>{{ $t("infobox.virtualStationSecondBullet") }}</li>
          <li>{{ $t("infobox.virtualStationThirdBullet") }}</li>
        </ul>
      </v-card-text>
      <v-card-text class="text-h6" style="color: #1a1a1a">
        {{ $t("infobox.virtualStationUpload") }}
      </v-card-text>
      <v-card-text>
        {{ $t("infobox.virtualStationUploadDescription") }}
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="closeDialog">
          {{ $t("close") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "VsInfo",
  data: function() {
    return {
      dialog: false
    };
  },
  methods: {
    closeDialog() {
      this.dialog = false;
    }
  }
};
</script>
.btn-fix:focus::before { opacity: 0 !important; }
<style scoped></style>
