import { mapActions, mapState } from "vuex";
import MapboxMixins from "@/core/mixins/mapbox.mixins";

let coastLayerMixin = {
  computed: {
    ...mapState("management", ["accessToken"]),
    ...mapState("raster", ["activeRasterLayer"]),
    activeRegionId() {
      return this.$route.params.regionId;
    }
  },
  mixins: [MapboxMixins],
  methods: {
    ...mapActions("management", ["getOrFetchAccessToken"]),
    ...mapActions("raster", [
      "setRasterLayer",
      "setLayerTimesteps",
      "setTempResLayerTimesteps"
    ]),
    addLayers(layerId, datetimeStep, accesstoken, place = "none") {
      if (place === "visualize") {
        this.addRasterLayer(
          "raster",
          layerId,
          datetimeStep,
          "z-index-2",
          accesstoken
        );
        this.addRasterLayer(
          "raster-terrain",
          layerId,
          datetimeStep,
          "z-index-1",
          accesstoken,
          "raster-dem"
        );
      }
    },
    async fetchLayers() {
      const client = this.$appConfig.keycloakClient;
      const response = await this.$rastless.get(
        `/layers?client=${client}&region_id=${this.activeRegionId}&token=${this.accessToken}`
      );
      if (response.data.length > 0) {
        this.setRasterLayer(response.data);
        return response.data;
      }
    },
    async fetchTimesteps(layerId) {
      const response = await this.$rastless.get(
        `/layers/${layerId}/steps?token=${this.accessToken}`
      );
      const data = response.data;
      this.setLayerTimesteps(data);
      return data;
    },
    addRasterLayer(
      layerType,
      layerId,
      step,
      zIndex,
      accessToken,
      type = "raster"
    ) {
      const domain = process.env.VUE_APP_RASTLESS_URL;
      const map = this.$store.visualizeMap.U;
      const name = this.getMapboxLayerName(layerId, type);
      let url = `${domain}/layers/${layerId}/${step.datetime}/tile/{z}/{x}/{y}.png?token=${accessToken}`;
      if (type === "raster-dem") {
        url += "&terrain=true";
      }
      if (layerType != "background") {
        this.hideTerrain(this.$store.visualizeMap);
      }
      this.$store.visualizeMap.U.removeSource(name.source);
      map.addRasterSource(name.source, {
        type: type,
        tiles: [url],
        tileSize: 256,
        bounds: step.bbox
      });
      map.addRasterLayer(name.layer, name.source, {}, zIndex);
    },

    removeRasterLayer(layerId, type = "raster", step = null) {
      let name = "";
      if (step === null) {
        name = this.getMapboxLayerName(layerId, type);
      } else {
        name = this.getMapboxLayerNameTimestep(layerId, step, type);
      }
      if (this.$store.visualizeMap.getLayer(name.layer)) {
        this.$store.visualizeMap.removeLayer(name.layer);
      }
      if (this.$store.visualizeMap.getSource(name.source)) {
        this.$store.visualizeMap.removeSource(name.source);
      }
    },

    addRasterLayerSlider(
      layerType,
      layerId,
      step,
      zIndex,
      accessToken,
      type = "raster"
    ) {
      const domain = process.env.VUE_APP_RASTLESS_URL;
      const map = this.$store.visualizeMap.U;
      const name = this.getMapboxLayerNameTimestep(
        layerId,
        step.datetime,
        type
      );
      let url = `${domain}/layers/${layerId}/${step.datetime}/tile/{z}/{x}/{y}.png?token=${accessToken}`;
      if (type === "raster-dem") {
        url += "&terrain=true";
      }
      if (this.$store.visualizeMap.getLayer(name.layer)) {
        this.$store.visualizeMap.removeLayer(name.layer);
      }
      if (this.$store.visualizeMap.getSource(name.source)) {
        this.$store.visualizeMap.removeSource(name.source);
      }
      map.addRasterSource(name.source, {
        type: type,
        tiles: [url],
        tileSize: 256,
        bounds: step.bbox
      });
      map.addRasterLayer(name.layer, name.source, {}, zIndex);
    },

    disableNextButton(selection, selected) {
      if (selection) {
        return selection.indexOf(selected) === selection.length - 1;
      }
      return false;
    },
    disablePreviousButton(selection, selected) {
      if (selection) {
        return selection.indexOf(selected) === 0;
      }
      return false;
    },
    nextDate(selection, selected) {
      let nextDatetime;
      const selectedPeriodIndex = selection.indexOf(selected);
      if (selectedPeriodIndex > 0) {
        nextDatetime = selection[selectedPeriodIndex - 1];
      } else if (selectedPeriodIndex === 0) {
        nextDatetime = selection[selection.length - 1];
      }
      return nextDatetime;
    },
    previousDate(selection, selected) {
      let previousDatetime;
      const selectedPeriodIndex = selection.indexOf(selected);
      if (selectedPeriodIndex < selection.length - 1) {
        previousDatetime = selection[selectedPeriodIndex + 1];
      }
      return previousDatetime;
    },
    datetimeFormat(item) {
      const dateTime = item.datetime.replace("T", "\u00A0\u00A0\u00A0\u00A0");
      return dateTime;
    },

    getMapboxLayerName(layerId, type) {
      return {
        source: `app-source-${layerId}-${type}`,
        layer: `app-layer-${layerId}-${type}`
      };
    },
    getMapboxLayerNameTimestep(layerId, layerStep, type) {
      return {
        source: `app-source-${layerId}-${layerStep}-${type}`,
        layer: `app-layer-${layerId}-${layerStep}-${type}`
      };
    },
    zoomToLayer() {
      // const geojson_bbox = bbox(geojson);
      this.$store.visualizeMap.fitBounds(this.activeRasterLayer.bbox, {
        padding: 100
      });
    }
  }
};

export default coastLayerMixin;
