<template>
  <v-container fluid grid-list-md class="fill-height ma-0 px-5">
    <v-layout rwo wrap>
      <v-flex class="overflow-y-auto overflow-x-hidden pr-0 sm-12">
        <v-row class="fill-height">
          <sediment-menu v-if="loaded" />
          <visualize @mapLoaded="addLayer" />
        </v-row>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import rasterLayerMixin from "@/core/mixins/rasterLayer.mixin";
import vectorLayerMixin from "@/core/mixins/vectorLayer.mixin";
import SedimentMenu from "@/core/components/menu/SedimentMenu.vue";
import Visualize from "@/core/views/MapView.vue";

export default {
  name: "Sediment",
  components: { Visualize, SedimentMenu },
  mixins: [rasterLayerMixin, vectorLayerMixin],
  data: () => ({
    showLabels: true,
    loaded: false
  }),
  computed: {
    ...mapState("raster", ["activeRasterLayer"]),
    ...mapState("vector", ["activeVectorLayer"]),
    ...mapState("management", ["accessToken"]),
    ...mapGetters("draw", ["virtualStationsByRegion"])
  },
  methods: {
    addLayer() {
      this.addRaster();
      this.addVectorLayer();
      this.addVirtualStations();
      this.loaded = true;
    },
    addRaster() {
      if (this.activeRasterLayer) {
        const layer = this.activeRasterLayer;
        const step = {
          bbox: layer.bbox,
          datetime: layer.datetime
        };
        this.addRasterLayer(
          "raster",
          layer.layerId,
          step,
          "z-index-2",
          this.accessToken
        );
      }
    },
    addVectorLayer() {
      if (this.activeVectorLayer.layerId) {
        // const layer = this.activeVectorLayer;
        this.addVector(this.activeVectorLayer);
        this.toggleLabels();
      }
    },
    addVirtualStations() {
      this.virtualStationsByRegion.forEach(station => {
        const feature = {
          id: station.id,
          type: "Feature",
          properties: {},
          geometry: station.geometry
        };
        this.$store.draw.add(feature);
      });
    }
  }
};
</script>

<style scoped></style>
