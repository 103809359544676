<template>
  <v-menu v-model="showMenu" :close-on-content-click="false" offset-x>
    <template v-slot:activator="{ on }">
      <v-icon small class="mr-2" v-on="on" :title="$t('changeName')">
        mdi-pencil
      </v-icon>
    </template>
    <v-card>
      <v-card-title class="pb-0">{{ $t("changeName") }}</v-card-title>
      <v-card-text>
        <v-text-field
          v-model="name"
          placeholder="Name"
          hide-details
        ></v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="cancel">
          {{ $t("cancel") }}
        </v-btn>
        <v-btn color="primary" text @click="save">
          {{ $t("save") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "RenameVirtualStation",
  props: ["item"],
  data: function() {
    return {
      showMenu: false,
      name: this.item.name
    };
  },
  methods: {
    ...mapActions("draw", ["updateName"]),
    cancel() {
      this.showMenu = false;
      this.name = this.item.name;
    },
    save() {
      this.updateName({ item: this.item, name: this.name });
      this.$emit("save-item", this.item);
      this.showMenu = false;
    }
  }
};
</script>

<style scoped></style>
