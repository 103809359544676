import axios from "axios";
import Url from "@/core/services/url.services";

let analyseMixin = {
  data: () => ({
    selectedVectorLayer: null,
    selectedSscLayer: null,
    selectedVectorParameter: null,
    selectedSscParameter: null
  }),
  methods: {
    linearFormulaLabel(coefficient) {
      let coefficient_string = "";
      coefficient.forEach((num, i) => {
        const elem = parseFloat(num).toFixed(2);
        const is_negative = elem < 0;
        if (i === 0) {
          coefficient_string += elem;
        } else if (i === 1) {
          if (is_negative) {
            coefficient_string += ` - ${Math.abs(elem)}x`;
          } else {
            coefficient_string += ` + ${elem}x`;
          }
        } else {
          if (is_negative) {
            coefficient_string += ` - ${Math.abs(elem)}x<sup>${i}</sup>`;
          } else {
            coefficient_string += ` + ${elem}x<sup>${i}</sup>`;
          }
        }
      });
      return `f(x) = ${coefficient_string}`;
    },
    exponentialFormulaLabel(coefficient) {
      let coefficient_a = "";
      let coefficient_b = "";
      if (!isNaN(coefficient[0])) {
        coefficient_a = parseFloat(coefficient[0]).toFixed(2);
      }
      if (!isNaN(coefficient[1])) {
        coefficient_b = parseFloat(coefficient[1]).toFixed(2);
      }
      return `f(x) = ${coefficient_a} * e<sup>(${coefficient_b}*x)</sup>`;
    },
    powerFormulaLabel(coefficient) {
      let coefficient_a = "";
      let coefficient_b = "";
      if (!isNaN(coefficient[0])) {
        coefficient_a = parseFloat(coefficient[0]).toFixed(2);
      }
      if (!isNaN(coefficient[1])) {
        coefficient_b = parseFloat(coefficient[1]).toFixed(2);
      }
      return `f(x) = ${coefficient_a} * x<sup>(${coefficient_b})</sup>`;
    },
    async fetchGeometryData() {
      this.inSituItemsLoading = true;
      const response = await axios.get(
        Url.geometryData(this.selectedVectorLayer.id)
      );
      this.inSituItems = response.data;
      this.inSituItemsLoading = false;
    },
    async fetchSscGeometryData() {
      this.inSituItemsLoading = true;
      const response = await axios.get(
        Url.geometryData(this.selectedSscLayer.id)
      );
      this.inSituSscItems = response.data;
      this.inSituItemsLoading = false;
    },
    daysLabel(value) {
      return Math.floor(value / 24);
    },
    hoursLabel(value) {
      return this.zeroDigit(value % 24);
    },
    zeroDigit(value) {
      return value.toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false
      });
    }
  },
  computed: {
    disabledVsSelection() {
      return !this.virtualStationsByRegion.length;
    },
    vsLabel() {
      return this.disabledVsSelection
        ? this.$t("correlationTool.noStation")
        : this.$t("correlationTool.virtualStation");
    },
    parameterItems() {
      if (this.selectedVectorLayer) {
        return this.selectedVectorLayer.properties;
      }
      return [];
    },
    parameterSscItems() {
      if (this.selectedSscLayer) {
        return this.selectedSscLayer.properties;
      }
      return [];
    }
  }
};

export default analyseMixin;
