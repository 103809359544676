<template>
  <div
    id="transect-window"
    class="flex-grow-0"
    style="align-self: end"
    :style="dialogStyle"
    v-show="showPlotFeature"
  >
    <dialog class="d-flex fill-height" style="width: 100%; border: 0 ">
      <div class="flex pl-2 pt-5">
        <v-list>
          <!--          <v-list-item v-if="selectedStation">-->
          <!--            <span>{{ selectedStation.name }}</span>-->
          <!--            <actions-menu class="ml-5" :item="selectedStation" @close-plot="resetDataCache"></actions-menu>-->
          <!--          </v-list-item>-->
          <v-list-item>
            <div ref="plot" id="bottom-plot" :style="plotStyle"></div>
            <v-overlay v-if="!dataCache.length" opacity="0.2" absolute>
              <div class="text-center">
                <v-progress-circular
                  indeterminate
                  color="primary"
                  size="64"
                ></v-progress-circular>
              </div>
            </v-overlay>
            <div style="position: absolute; right: 220px; bottom: -2px">
              <v-btn color="primary" icon @click="modePoints = !modePoints">
                <v-icon v-if="modePoints">mdi-ray-vertex</v-icon>
                <v-icon x-small v-if="!modePoints">mdi-record</v-icon>
              </v-btn>
            </div>

            <div style="position: absolute; right: 20px; bottom: 0px">
              <v-menu offset-y :close-on-content-click="false">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="primary" text v-bind="attrs" v-on="on">
                    {{ $t("ExportAndReport") }}
                    <v-icon>mdi-chevron-down</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item class="pa-0 justify-center">
                    <v-btn
                      color="primary"
                      text
                      @click="createPdfReport"
                      :disabled="createReportLoading"
                    >
                      <v-progress-circular
                        indeterminate
                        color="primary"
                        v-if="createReportLoading"
                        size="15"
                        width="2"
                      ></v-progress-circular>
                      {{ $t("createPlotReport") }}
                    </v-btn>
                  </v-list-item>
                  <v-list-item class="pa-0 justify-center">
                    <v-btn
                      color="primary"
                      text
                      @click="exportData"
                      :disabled="exportDataLoading"
                    >
                      <v-progress-circular
                        indeterminate
                        color="accent"
                        v-if="exportDataLoading"
                        size="15"
                        width="2"
                      ></v-progress-circular>
                      {{ $t("exportPlotData") }}
                    </v-btn>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </v-list-item>
        </v-list>
      </div>
      <div style="position: absolute; right: 0">
        <v-btn color="primary" text @click="reset()">
          {{ $t("reset") }}
        </v-btn>
        <v-btn color="primary" text @click="setShowFullSizePlot(true)">
          <v-icon>{{ fullscreenIcon }}</v-icon>
        </v-btn>
        <v-btn icon variant="plain" @click="setShowPlotFeature(false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
    </dialog>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from "vuex";
import Plotly from "plotly.js-dist";
import virtualStationMixin from "@/core/mixins/virtualStation.mixin";
import ReportUtils from "@/core/utils/report.utils";
import JsPDF from "jspdf";
import moment from "moment";

export default {
  name: "BottomPlot",
  mixins: [virtualStationMixin],
  data: () => ({
    fullscreen: false,
    show: false,
    height: 230,
    width: 1300,
    modePoints: true,
    layout: {
      showlegend: true,
      margin: {
        l: 40,
        r: 50,
        b: 30,
        t: 30,
        pad: 6
      },
      xaxis: {
        tickfont: {
          size: 14
        }
      },
      yaxis: {
        tickfont: {
          size: 14
        }
      },
      legend: {
        font: {
          size: 14
        }
      }
    },
    config: {
      responsive: true,
      modeBarButtonsToRemove: [
        "toggleHover",
        "sendDataToCloud",
        "toggleSpikelines",
        "resetScale2d",
        "pan2d",
        "zoom2d",
        "select2d",
        "lasso2d"
      ]
    },
    createReportLoading: false,
    exportDataLoading: false,
    temp: null
  }),
  computed: {
    ...mapState("raster", ["activeRasterLayer"]),
    ...mapState("plot", [
      "dataCache",
      "selectedStation",
      "showTransectPlot",
      "showPlotFeature",
      "showPlot"
    ]),
    ...mapState("coastsPlot", ["profileLayer"]),
    ...mapState("management", ["tempResolution"]),
    ...mapGetters("plot", ["entriesDataCacheFilled"]),
    chart() {
      return {
        traces: this.dataCache,
        layout: {
          title: "Data Plot"
        }
      };
    },
    fullscreenIcon() {
      return this.fullscreen ? "mdi-fullscreen-exit" : "mdi-fullscreen";
    },
    dialogStyle() {
      return { width: "100%", height: "40vh" };
    },
    plotStyle() {
      return this.fullscreen ? { height: "100vh" } : { height: "25vh" };
    }
  },
  methods: {
    ...mapActions("app", ["showSnackbar"]),
    ...mapActions("plot", [
      "resetDataCache",
      "setLineStation",
      "setShowTransectPlot",
      "setShowPlotFeature",
      "setShowPlot",
      "setShowFullSizePlot",
      "setStation"
    ]),
    ...mapActions("coastsPlot", ["setShowStationPlot", "setShowProfilePlot"]),
    reset() {
      this.resetDataCache();
      this.setStation(null);
      this.setShowPlot(false);
      this.setShowPlotFeature(false);
    },
    createPlot() {
      Plotly.newPlot("bottom-plot", this.dataCache, this.layout, this.config);
      this.addDefaultClickEvent();
    },
    addDefaultClickEvent() {
      if (this.tempResolution != "custom") {
        const _this = this;
        this.$refs.plot.on("plotly_click", function(data) {
          _this.$emit(
            "changeTimeStep",
            data.points[0].data.steps[data.points[0].pointIndex]
          );
        });
      } else {
        let plotDiv = document.getElementById("bottom-plot");
        plotDiv.removeAllListeners("plotly_click");
      }
    },
    updatePlot() {
      this.dataCache.forEach(plot => {
        if (this.modePoints) {
          plot.mode = "markers";
        } else {
          plot.mode = "makers+line";
        }
      });
      Plotly.react("bottom-plot", this.dataCache, this.layout);
      this.addDefaultClickEvent();
    },
    exportData() {
      this.exportDataLoading = true;
      let data = ReportUtils.createBody(this.dataCache);
      data.unshift(ReportUtils.createHead());

      let description = ReportUtils.createCsvDescription();

      let csvContent =
        "data:text/csv;charset=utf-8," +
        "eoPortal Data Export\n\n" +
        description +
        "\n\n" +
        data.map(e => e.join(";")).join("\n");

      let encodedUri = encodeURI(csvContent);
      let link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "eoPortal_Data.csv");
      document.body.appendChild(link);
      link.click();
      this.showSnackbar({
        show: true,
        message: this.$t("exportDataMessage"),
        color: "success"
      });
      this.exportDataLoading = false;
    },
    async createPdfReport() {
      this.createReportLoading = true;
      const x = 15;
      let pdfName = `report_eoapp_${this.$appConfig.keycloakClient}_datasets_${
        this.dataCache.length
      }_created_${moment().format("YYYY-MM-DDTHH-mm-ss")}`;
      let doc = new JsPDF();
      let logo = this.$appConfig.logo;
      if (this.$appConfig.logoPNG) {
        logo = this.$appConfig.logoPNG;
      }
      let y = ReportUtils.addHeaderToPdf(doc, x, this.$appConfig.appName, logo);
      y = await ReportUtils.addPlotToPdf(doc, x, y + 5, this.$refs.plot);
      y = ReportUtils.createDescription(doc, x, y + 10);
      ReportUtils.addTableToPdf(doc, y + 4);
      doc.save(pdfName + ".pdf");
      this.showSnackbar({
        show: true,
        message: this.$t("createReportMessage"),
        color: "success"
      });
      this.createReportLoading = false;
    }
  },
  mounted() {
    if (this.showTransectPlot) {
      this.setLineStation(null);
      this.setShowTransectPlot(false);
    }
    this.setShowStationPlot(false);
    this.setShowProfilePlot(false);
    this.setShowPlot(true);
    this.createPlot();
  },
  watch: {
    showPlotFeature() {
      if (this.showPlotFeature) {
        this.updatePlot();
      }
    },
    dataCache() {
      this.updatePlot();
    },
    "activeRasterLayer.layerId"() {
      if (this.showPlotFeature) {
        if (this.activeRasterLayer.product !== "rgb" && this.selectedStation) {
          this.addTimeseriesToPlot();
        }
      }
    },
    "profileLayer.layerId"() {
      if (this.showPlotFeature) {
        this.addTimeseriesToPlot(this.profileLayer);
      }
    },
    modePoints() {
      this.dataCache.forEach(plot => {
        if (this.modePoints) {
          plot.mode = "markers";
        } else {
          plot.mode = "lines+markers";
        }
      });
      Plotly.newPlot("bottom-plot", this.dataCache, this.layout, this.config);
      this.addDefaultClickEvent();
    }
  }
};
</script>

<style scoped>
#bottom-plot {
  width: 90%;
}
</style>
