<template>
  <v-dialog v-model="dialog" width="auto" max-width="700">
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="primary" text outlined v-bind="attrs" v-on="on">
        {{ $t("sedimentTool.calculatePortalData") }}
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="font-weight-regular">{{ cardTitle }}</v-card-title>
      <v-card-text class="pl-10">
        <v-row>
          <p class="font-weight-bold black--text mt-4 mr-2">
            {{ $t("correlationToolDischarge.eoTurbidity") }}
          </p>
          <title-description-info
            :title="$t('correlationToolDischarge.dischargeData')"
            :description="$t('infobox.dischargeDataDescription')"
          />
        </v-row>
        <v-select
          style="width: 70%"
          :label="$t('correlationTool.rasterLayer')"
          :items="rasterLayer"
          v-model="selectedRasterLayerId"
          item-value="layerId"
          item-text="title"
          dense
          class="pt-3"
        ></v-select>
        <v-autocomplete
          style="width: 70%"
          v-model="selectedVirtualStation"
          :items="virtualStationsByRegion"
          item-text="name"
          :disabled="disabledVsSelection"
          return-object
          :label="vsLabel"
          dense
          class="pb-2"
        />

        <v-row>
          <p class="font-weight-bold black--text mt-4 mr-2">
            {{ $t("correlationToolDischarge.inSituSsc") }}
          </p>
          <title-description-info
            :title="$t('correlationToolDischarge.dischargeData')"
            :description="$t('infobox.dischargeDataDescription')"
          />
        </v-row>
        <v-select
          style="width: 70%"
          :label="$t('correlationTool.vectorLayer')"
          :items="vectorLayer"
          v-model="selectedVectorLayer"
          return-object
          item-value="id"
          item-text="name"
          class="pt-2"
          @change="fetchGeometryData"
          dense
        />
        <v-select
          style="width: 70%"
          :label="$t('correlationTool.vectorLayerParam')"
          :items="parameterItems"
          v-model="selectedVectorParameter"
          item-value="id"
          item-text="name"
          dense
        />
        <v-autocomplete
          style="width: 70%"
          v-model="selectedInSituStation"
          :items="inSituItems"
          :loading="inSituItemsLoading"
          item-text="name"
          return-object
          :label="$t('correlationTool.inSituStation')"
          dense
          class="pb-2"
        />
        <v-alert color="red" text type="error" v-if="error">
          {{ $t("correlationTool.errorMessage") }}
        </v-alert>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn class="mr-5" text color="primary" @click="dialog = !dialog">
          {{ $t("close") }}
        </v-btn>
        <v-btn
          :disabled="!allSelected"
          color="primary"
          @click="triggerCalculationCorrelation"
        >
          {{ $t("continue") }}
          <v-icon class="pl-3" v-if="loadResults">mdi-spin mdi-loading</v-icon>
        </v-btn>
        <correlation-create-formula
          :dialog="createFormel"
          :option="'correlation'"
          :result="result"
          :selected-in-situ-station="selectedInSituStation"
          :selected-raster-layer-id="selectedRasterLayerId"
          :selected-virtual-station="selectedVirtualStation"
          :selected-vector-parameter="selectedVectorParameter"
          :card-title="cardTitleFormula"
          :loadResults="loadResults"
          @closeCorrelation="
            dialog = false;
            createFormel = false;
          "
          @closeFormula="createFormel = false"
          @changeSelectedDate="setSelectedDate"
          @changeMaxDif="setMaxTimeDifValue"
          @changeSelectedFunction="setSelectedFunction"
        ></correlation-create-formula>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import TitleDescriptionInfo from "@/core/components/infos/TitleDescriptionInfo.vue";
import { mapGetters, mapState } from "vuex";
import analyseMixin from "@/core/mixins/analyse.mixin";
import axios from "axios";
import Url from "@/core/services/url.services";
import CorrelationCreateFormula from "@/core/components/menu/eotool/analyse/CorrelationCreateFormula.vue";

export default {
  name: "CorrelationInputData",
  mixins: [analyseMixin],
  components: { CorrelationCreateFormula, TitleDescriptionInfo },
  data() {
    return {
      dialog: false,
      createFormel: false,
      error: false,
      selectedVirtualStation: null,
      selectedInSituStation: null,
      selectedRasterLayerId: null,
      inSituItems: [],
      inSituItemsLoading: false,
      loadResults: false,
      selectedFunction: "exponential",
      maxTimeDif: 24,
      maxTimeDifValue: 24,
      selectedDate: [],
      result: {
        coefficients: [1, 1],
        data_entries: 0,
        x: [],
        y: [],
        fitting_algorithm: ""
      }
    };
  },
  computed: {
    ...mapGetters("draw", ["virtualStationsByRegion"]),
    ...mapState("vector", ["activeVectorLayer", "vectorLayer"]),
    ...mapState("raster", ["rasterLayer"]),
    cardTitle() {
      return `
      ${this.$t(
        "correlationToolDischarge.correlationTurbidity"
      )} -> SSC, 1 ${this.$t("of")} 2: ${this.$t(
        "correlationToolDischarge.selectInputData"
      )}`;
    },
    cardTitleFormula() {
      return `
      ${this.$t(
        "correlationToolDischarge.correlationTurbidity"
      )} -> SSC, 2 ${this.$t("of")} 2: ${this.$t(
        "correlationToolDischarge.createFormula"
      )}
`;
    },
    showSaveButton() {
      return !this.error && this.$keycloak.authenticated;
    },
    showResult() {
      return this.result.data_entries !== 0 && !this.error;
    },
    dataWatcher() {
      return [
        this.selectedVirtualStation,
        this.selectedInSituStation,
        this.maxTimeDifValue,
        this.selectedRasterLayerId,
        this.selectedVectorLayer,
        this.selectedVectorParameter,
        this.selectedFunction,
        this.selectedDate
      ];
    },
    allSelected() {
      return this.dataWatcher.every(v => !!v);
    }
  },
  methods: {
    triggerCalculationCorrelation() {
      if (this.allSelected) {
        this.calculateCorrelation();
      }
    },
    async calculateCorrelation() {
      this.loadResults = true;
      const virtual_station_geom = this.$store.draw.get(
        this.selectedVirtualStation.id
      );
      const data = {
        virtual_station_geom: virtual_station_geom.geometry,
        raster_layer: this.selectedRasterLayerId,
        layer_geometry: this.selectedInSituStation.id,
        insitu_property: this.selectedVectorParameter,
        max_time_dif: this.maxTimeDifValue,
        fitting_algorithm: this.selectedFunction,
        datetime_selection: this.datetimeLimit,
        start_date: this.selectedDate[0],
        end_date: this.selectedDate[1]
      };
      const response = await axios.post(Url.calculateCorrelation, data);
      if (response.status === 204) {
        this.error = true;
      } else if (response.status === 200) {
        this.error = false;
        this.result = response.data;
        this.createFormel = true;
      }
      this.loadResults = false;
    },
    setMaxTimeDifValue(val) {
      this.maxTimeDifValue = val;
    },
    setSelectedFunction(val) {
      this.selectedFunction = val;
    },
    setSelectedDate(val) {
      this.selectedDate = val;
    }
  },
  watch: {
    selectedVectorLayer: function() {
      if (!this.selectedVectorParameter) {
        const dischargeParam = this.parameterItems.filter(param =>
          param["name"].toLowerCase().includes("discharge")
        )[0];
        if (dischargeParam) {
          this.selectedVectorParameter = dischargeParam.id;
        }
      }
    },
    activeVectorLayer: function() {
      this.fetchGeometryData();
    },
    dataWatcher: function() {
      if (this.createFormel && this.allSelected) {
        this.calculateCorrelation();
      }
    }
  },
  created() {
    const turLayer = this.rasterLayer.filter(layer =>
      layer["title"].toLowerCase().includes("turb")
    )[0];
    if (turLayer) {
      this.selectedRasterLayerId = turLayer.layerId;
    }
  }
};
</script>

<style scoped></style>
