<template>
  <div>
    <v-dialog v-model="showFullSizePlot" :fullscreen="fullscreen">
      <v-card>
        <v-btn absolute right top icon @click="closeDialog" id="close-btn">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <div ref="plot" id="dataPlotPopup" style="width: 100vw; height: 94vh" />
        <v-divider />
        <v-card-actions>
          <v-menu offset-y :close-on-content-click="false">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" text v-bind="attrs" v-on="on">
                {{ $t("ExportAndReport") }}
                <v-icon>mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item class="pa-0 justify-center">
                <v-btn
                  color="primary"
                  text
                  @click="createPdfReport"
                  :disabled="createReportLoading"
                >
                  <v-progress-circular
                    indeterminate
                    color="primary"
                    v-if="createReportLoading"
                    size="15"
                    width="2"
                  ></v-progress-circular>
                  {{ $t("createPlotReport") }}
                </v-btn>
              </v-list-item>
              <v-list-item class="pa-0 justify-center">
                <v-btn
                  color="primary"
                  text
                  @click="exportData"
                  :disabled="exportDataLoading"
                >
                  <v-progress-circular
                    indeterminate
                    color="accent"
                    v-if="exportDataLoading"
                    size="15"
                    width="2"
                  ></v-progress-circular>
                  {{ $t("exportPlotData") }}
                </v-btn>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-spacer />
          <v-btn color="primary" icon @click="modePoints = !modePoints">
            <v-icon v-if="modePoints">mdi-ray-vertex</v-icon>
            <v-icon x-small v-if="!modePoints">mdi-record</v-icon>
          </v-btn>
          <v-btn color="primary" text @click="resetDataCloseDialog">
            {{ $t("resetPlotData") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import Plotly from "plotly.js-dist";
import ReportUtils from "@/core/utils/report.utils";
import JsPDF from "jspdf";
import "jspdf-autotable";

export default {
  name: "DataPlotPopup",
  data: () => ({
    fullscreen: true,
    layout: {
      showlegend: true,
      xaxis: {
        tickfont: {
          size: 15
        }
      },
      yaxis: {
        tickfont: {
          size: 15
        }
      },
      legend: {
        font: {
          size: 15
        }
      }
    },
    config: {
      responsive: true,
      displaylogo: false
    },
    createReportLoading: false,
    exportDataLoading: false,
    modePoints: true
  }),
  computed: {
    ...mapState("plot", ["dataCache"]),
    ...mapGetters("plot", ["entriesDataCacheFilled"]),
    showFullSizePlot: {
      get() {
        return this.$store.state.plot.showFullSizePlot;
      },
      set(value) {
        this.$store.dispatch("plot/setShowFullSizePlot", value);
      }
    },
    fullscreenIcon() {
      return this.fullscreen ? "mdi-fullscreen-exit" : "mdi-fullscreen";
    },
    chart() {
      return {
        traces: this.dataCache,
        layout: {
          title: "Data Plot"
        }
      };
    }
  },
  methods: {
    ...mapActions("app", ["showSnackbar"]),
    ...mapActions("plot", ["resetDataCache"]),
    closeDialog() {
      this.$store.dispatch("plot/setShowFullSizePlot", false);
    },
    resetDataCloseDialog() {
      this.resetDataCache();
      Plotly.relayout("dataPlotPopup", {
        "xaxis.autorange": true,
        "yaxis.autorange": true
      });
      this.$store.dispatch("plot/setShowFullSizePlot", false);
    },
    createPlot() {
      Plotly.newPlot("dataPlotPopup", this.dataCache, this.layout, this.config);
    },
    updatePlot() {
      this.dataCache.forEach(plot => {
        if (this.modePoints) {
          plot.mode = "markers";
        } else {
          plot.mode = "makers+line";
        }
      });
      Plotly.react("dataPlotPopup", this.dataCache, this.layout);
    },

    exportData() {
      this.exportDataLoading = true;
      let data = ReportUtils.createBody(this.dataCache);
      data.unshift(ReportUtils.createHead());

      let description = ReportUtils.createCsvDescription();

      let csvContent =
        "data:text/csv;charset=utf-8," +
        "eoPortal Data Export\n\n" +
        description +
        "\n\n" +
        data.map(e => e.join(";")).join("\n");

      let encodedUri = encodeURI(csvContent);
      let link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "eoPortal_Data.csv");
      document.body.appendChild(link);
      link.click();
      this.showSnackbar({
        show: true,
        message: this.$t("exportDataMessage"),
        color: "success"
      });
      this.exportDataLoading = false;
    },
    async createPdfReport() {
      this.createReportLoading = true;
      const x = 15;
      let pdfName = `eoPortal Report ${new Date().toLocaleString()}`;
      let doc = new JsPDF();
      let logo = this.$appConfig.logo;
      if (this.$appConfig.logoPNG) {
        logo = this.$appConfig.logoPNG;
      }
      let y = ReportUtils.addHeaderToPdf(doc, x, this.$appConfig.appName, logo);
      y = await ReportUtils.addPlotToPdf(doc, x, y + 5, this.$refs.plot);
      y = ReportUtils.createDescription(doc, x, y + 10);
      ReportUtils.addTableToPdf(doc, y + 4);
      doc.save(pdfName + ".pdf");
      this.showSnackbar({
        show: true,
        message: this.$t("createReportMessage"),
        color: "success"
      });
      this.createReportLoading = false;
    }
  },
  watch: {
    modePoints() {
      this.dataCache.forEach(plot => {
        if (this.modePoints) {
          plot.mode = "markers";
        } else {
          plot.mode = "lines+markers";
        }
      });
      Plotly.newPlot("dataPlotPopup", this.dataCache, this.layout, this.config);
    }
  },
  updated() {
    if (this.entriesDataCacheFilled) {
      this.createPlot();
      this.$watch("dataCache", function() {
        this.updatePlot();
      });
    }
  }
};
</script>

<style scoped>
#close-btn {
  z-index: 1;
}
</style>
