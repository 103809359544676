import { render, staticRenderFns } from "./CorrelationInputData.vue?vue&type=template&id=3ce10804&scoped=true"
import script from "./CorrelationInputData.vue?vue&type=script&lang=js"
export * from "./CorrelationInputData.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3ce10804",
  null
  
)

export default component.exports