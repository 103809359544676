<template>
  <div class="d-inline-block">
    <template>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn elevation="0" icon v-bind="attrs" v-on="on">
            <v-icon color="primary" @click="openUploadWindow"
              >mdi-upload</v-icon
            >
          </v-btn>
        </template>
        <span>{{ $t("infobox.virtualStationUpload") }}</span>
      </v-tooltip>

      <input ref="fileDiv" class="d-none" type="file" @change="uploadFile" />
    </template>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { v4 as uuidv4 } from "uuid";
import virtualStation from "@/core/services/virtualStation.services";
import { postProj } from "@/core/api/proj.api";

export default {
  name: "UploadVirtualStation",
  props: {
    uploadTitle: {
      type: String,
      default: "uploadData"
    }
  },
  data: function() {
    return {
      uploadedFile: null
    };
  },
  computed: {
    ...mapGetters("draw", ["selectedFeature"]),
    ...mapGetters("management", ["activeRegion"])
  },
  methods: {
    ...mapActions("draw", ["addVirtualStation", "setVSPermanent"]),
    ...mapActions("app", ["showSnackbar"]),
    openUploadWindow() {
      this.$refs.fileDiv.click();
    },
    async uploadFile(event) {
      const fileInput = event.target;
      if (fileInput.files.length > 0) {
        this.uploadedFile = fileInput.files[0];
        try {
          const convertedFileData = await postProj(this.uploadedFile);
          this.processFeatures(convertedFileData);
          convertedFileData.features.forEach(feature => {
            this.createVirtualStation(feature);
          });
        } catch (e) {
          console.log(e);
          this.showSnackbar({
            show: true,
            message: this.$t("infobox.virtualStationUploadError"),
            color: "error",
            timeout: 6000
          });
        }
      }
    },
    processFeatures(geojson) {
      if (geojson.type == "FeatureCollection") {
        geojson.features.forEach(feature => {
          if (feature.geometry.type.includes("Multi")) {
            feature.geometry.type = feature.geometry.type.slice(5);
            feature.geometry.coordinates = feature.geometry.coordinates[0];
          }
        });
      }
    },
    createVirtualStation(feature) {
      try {
        feature.id = uuidv4();
        const nameObject = Object.keys(feature.properties)
          .filter(prop => prop.includes("name") || prop.includes("Name"))
          .reduce((cur, prop) => {
            return Object.assign(cur, { [prop]: feature.properties[prop] });
          }, {});
        feature.name = Object.values(nameObject)[0];
        this.$store.draw.add(feature);
        this.addVirtualStation(feature);
        virtualStation.save(feature.id);
        this.setVSPermanent(feature.id);
      } catch (e) {
        console.log(e);
        this.showSnackbar({
          show: true,
          message: this.$t("infobox.virtualStationCannotReadFile"),
          color: "error",
          timeout: 6000
        });
      }
    }
  }
};
</script>

<style scoped>
.file-settings {
  margin-top: 5px;
}
</style>
