<template>
  <div
    id="transect-window"
    class="flex-grow-0"
    style="align-self: end; height: 40vh"
    v-if="showPlotFeature"
  >
    <dialog class="d-flex fill-height" style="width: 100%; border: 0 ">
      <div class="pa-2">
        <v-list>
          <v-list-item>
            <v-tooltip>
              <template v-slot:activator="{ props }">
                <v-btn
                  fab
                  v-bind="props"
                  color="primary"
                  small
                  @click="fetchTransect(activeRasterLayer.datetime)"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </template>
            </v-tooltip>
          </v-list-item>
          <!--          <v-list-item>-->
          <!--            <v-tooltip>-->
          <!--              <template v-slot:activator="{ props }">-->
          <!--                <v-btn-->
          <!--                  fab-->
          <!--                  v-bind="props"-->
          <!--                  color="primary"-->
          <!--                  small-->
          <!--                  @click="play = true"-->
          <!--                >-->
          <!--                  <v-icon>mdi-play</v-icon>-->
          <!--                </v-btn>-->
          <!--              </template>-->
          <!--            </v-tooltip>-->
          <!--          </v-list-item>-->
        </v-list>
      </div>
      <div class="flex">
        <v-list>
          <!--          <v-list-item v-if="selectedStation">-->
          <!--            <span>{{ selectedStation.name }}</span>-->
          <!--            <actions-menu class="ml-5" :item="selectedStation" @close-plot="resetDataCache"></actions-menu>-->
          <!--          </v-list-item>-->
          <v-list-item>
            <plotly
              :data="data"
              :layout="layout"
              :showSendToCloud="true"
              :responsive="true"
              style="height: 25vh; width: 90% !important;"
            ></plotly>
            <div style="position: absolute; right: 20px; bottom: 0px">
              <v-btn color="primary" text @click="exportData"
                >{{ $t("exportPlotData") }}
                <v-icon>mdi-download</v-icon>
              </v-btn>
            </div>
          </v-list-item>
          <!--          <v-list-item class="py-0 pr-16 mr-7 justify-end">-->
          <!--            <div>-->
          <!--              <v-btn color="primary" @click="exportData"-->
          <!--              >{{ $t("exportPlotData") }}-->
          <!--              </v-btn>-->
          <!--            </div>-->
          <!--          </v-list-item>-->
        </v-list>
      </div>
      <div style="position: absolute; right: 0">
        <v-btn color="primary" text @click="reset()">
          {{ $t("reset") }}
        </v-btn>
        <v-btn icon variant="plain" @click="setShowPlotFeature(false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
    </dialog>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import virtualStationMixin from "@/core/mixins/virtualStation.mixin";
import { Plotly } from "vue-plotly";

export default {
  name: "BottomPlotTransect",
  components: { Plotly },
  mixins: [virtualStationMixin],
  data: () => ({
    fullscreen: false,
    data: [],
    play: false,
    selectedDatetimes: [],
    createReportLoading: false,
    exportDataLoading: false
  }),
  computed: {
    ...mapState("raster", ["layerTimesteps", "activeRasterLayer"]),
    ...mapState("plot", ["lineStation", "showPlotFeature"]),
    ...mapState("management", ["accessToken"]),
    plotEntries() {
      return this.data.map(x => x.id);
    },
    layout() {
      return {
        showLegend: true,
        xaxis: {
          title: {
            text: this.$t("transect.meter")
          }
        },
        yaxis: {
          title: {
            text: this.activeRasterLayer.unit
          }
        },
        margin: {
          l: 40,
          r: 50,
          b: 50,
          t: 50,
          pad: 6
        }
      };
    }
  },
  methods: {
    ...mapActions("plot", [
      "setShowTransectPlot",
      "setLineStation",
      "resetDataCache",
      "setShowPlotFeature"
    ]),
    ...mapActions("coastsPlot", ["setShowStationPlot", "setShowProfilePlot"]),
    reset() {
      this.setShowTransectPlot(false);
      this.setLineStation(false);
      this.setShowPlotFeature(false);
    },
    datetimeFormat: item => item.datetime.replace("T", " "),
    async fetchTransect(datetime) {
      const response = await this.$rastless.post(
        `/layers/${this.activeRasterLayer.layerId}/${datetime}/transect?token=${this.accessToken}`,
        this.lineStation.geometry
      );
      const data = response.data;

      for (const [key, values] of Object.entries(data.data)) {
        const dataEntry = {
          id: datetime,
          name: `${datetime.replace("T", " ")} ${key}`,
          x: data.cumulated_length,
          y: values,
          coords: data.coordinates,
          showlegend: true
        };
        this.data.push(dataEntry);
      }
    },
    toggleDateEntry(entry) {
      const dateEntriesToAdd = entry.filter(x => !this.plotEntries.includes(x));
      dateEntriesToAdd.forEach(datetime => this.fetchTransect(datetime));

      const dateEntriesToRemove = this.plotEntries.filter(
        x => !entry.includes(x)
      );
      dateEntriesToRemove.forEach(datetime => this.deletePlotEntry(datetime));
    },
    deletePlotEntry(datetime) {
      this.data = this.data.filter(plotEntry => plotEntry.id !== datetime);
    },
    exportData() {
      const csvContent = this.createCsvContent();
      const encodedUri = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "transect.csv");
      document.body.appendChild(link); // Required for FF
      link.click();
    },
    createCsvContent() {
      const heading = this.data.map(entry => entry.name);
      heading.unshift("Length [m]", "Lat", "Lon");

      let rows = [heading];

      const numberOfEntriesIndexes = [...Array(this.data.length).keys()];

      this.data[0].x.forEach((lengthInMeters, lengthIndex) => {
        const row = numberOfEntriesIndexes.map(
          layerIndex => this.data[layerIndex].y[lengthIndex] || ""
        );
        row.unshift(
          lengthInMeters,
          this.data[0].coords[lengthIndex][1],
          this.data[0].coords[lengthIndex][0]
        );
        rows.push(row);
      });

      return (
        "data:text/csv;charset=utf-8," + rows.map(e => e.join(",")).join("\n")
      );
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.setShowStationPlot(false);
      this.setShowProfilePlot(false);
      this.setShowPlot(false);
      this.fetchTransect(this.activeRasterLayer.datetime);
      this.setShowTransectPlot(true);
      this.setShowPlotFeature(true);
      // this.showPlot = true;
    });
  },
  watch: {
    lineStation() {
      this.fetchTransect(this.activeRasterLayer.datetime);
    }
    // dataCache() {
    //   this.fetchTransect(this.activeRasterLayer.datetime);
    // },
    // showPlotFeature() {
    //   if (this.showPlotFeature) {
    //     // console.log("create plot");
    //     setTimeout(() => {
    //       this.fetchTransect(this.activeRasterLayer.datetime);
    //     });
    //
    //   }
    // }
  }
};
</script>

<style scoped>
#bottom-plot {
  width: 90%;
}
</style>
