import { mapGetters, mapState } from "vuex";

let vectorLayerMixin = {
  computed: {
    ...mapGetters("management", ["activeRegion"]),
    ...mapGetters("management", ["activeRegionBbox"]),
    ...mapState("inSitu", ["inSituStations"]),
    sourceSettings() {
      let settings = {
        bounds: this.activeRegionBbox
      };
      const minZoom = this.activeRegion.properties.min_zoom;
      if (minZoom) {
        settings["minzoom"] = minZoom;
      }
      return settings;
    }
  },
  methods: {
    addImage(map) {
      map.loadImage(require("/src/core/assets/xylem/arrow-up.png"), function(
        error,
        image
      ) {
        if (error) throw error;
        if (map.hasImage("arrow-marker")) {
          map.removeImage("arrow-marker");
        }
        map.addImage("arrow-marker", image, {
          sdf: "true"
        });
      });
    },
    addVector({ layerId, parameterId, startDate, endDate, style }) {
      const map = this.$store.visualizeMap;
      const domain = process.env.VUE_APP_BASE_URL;
      const client = this.$appConfig.keycloakClient;
      let url = `${domain}/vector/${client}/mvt/${layerId}/{z}/{x}/{y}?property=${parameterId}`;
      if (startDate) {
        startDate = startDate.replace(" ", "T");
        url += `&start_date=${startDate}`;
      }
      if (endDate) {
        if (endDate.length === 10) {
          endDate += "T23:59:59";
        }
        url += `&end_date=${endDate}`;
      }

      map.U.addVector("vectorSource", url, this.sourceSettings);

      this.addImage(map);

      map.addLayer(
        {
          id: "vectorLayer",
          type: style.type,
          source: "vectorSource",
          "source-layer": "default",
          layout: style.layout,
          paint: style.paint
        },
        "z-index-3"
      );
    },
    toggleLabels() {
      if (this.showLabels) {
        this.$store.visualizeMap.addLayer(
          {
            id: "vectorLabels",
            type: "symbol",
            source: "vectorSource",
            "source-layer": "default",
            layout: {
              "text-field": ["get", "name"],
              "text-variable-anchor": [
                "top",
                "bottom",
                "left",
                "right",
                "top-right",
                "top-left",
                "bottom-right",
                "bottom-left"
              ],
              "text-radial-offset": 0.5,
              "text-justify": "auto"
            },
            paint: {
              "text-color": "#072480"
            }
          },
          "z-index-3"
        );
      } else {
        if (this.$store.visualizeMap.getLayer("vectorLabels")) {
          this.$store.visualizeMap.removeLayer("vectorLabels");
        }
      }
    },
    updateLabelStyle(noOfLabels) {
      const numberOfLabels = noOfLabels;
      let textRadialOffset;
      if (numberOfLabels > 2) {
        textRadialOffset = 5.5;
      } else {
        textRadialOffset = 0.5;
      }

      if (this.$store.visualizeMap.getLayer("vectorLabels")) {
        this.$store.visualizeMap.setLayoutProperty(
          "vectorLabels",
          "text-radial-offset",
          textRadialOffset
        );
      }
    }
  },
  watch: {
    inSituStations: {
      immediate: true,
      handler(inSituStations) {
        if (inSituStations && inSituStations.length > 0) {
          this.updateLabelStyle(inSituStations.length);
        }
      }
    }
  }
};

export default vectorLayerMixin;
