<template>
  <v-dialog v-model="dialog" width="auto" max-width="900">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="primary"
        elevation="2"
        v-bind="attrs"
        v-on="on"
        :disabled="disabled"
      >
        {{ $t("correlationTool.plotAndSave") }}
      </v-btn>
    </template>
    <v-card>
      <v-card-title>{{ $t("correlationTool.plotTitle") }}</v-card-title>
      <v-card-text>
        <Plotly
          :data="data"
          :layout="layout"
          :display-mode-bar="false"
        ></Plotly>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-btn
          text
          color="primary"
          @click="exportPlotData"
          v-if="!exportLoading"
        >
          {{ $t("exportPlotData") }}
        </v-btn>
        <v-progress-circular
          v-else
          indeterminate
          color="primary"
        ></v-progress-circular>
        <v-spacer />
        <v-btn text color="primary" @click="dialog = !dialog"
          >{{ $t("close") }}
        </v-btn>
        <v-btn color="primary" @click="saveCorrelation"
          >{{ $t("save") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { Plotly } from "vue-plotly";

export default {
  name: "CorrelationPlot",
  components: {
    Plotly
  },
  props: {
    disabled: {
      type: Boolean
    },
    x: {
      type: Array
    },
    y: {
      type: Array
    },
    coefficients: {
      type: Array
    },
    fittingAlgorithm: {
      type: String
    },
    exportLoading: {
      type: Boolean,
      default: false
    },
    labelX: {
      type: String,
      default: "correlationTool.rasterLayer"
    },
    labelY: {
      type: String,
      default: "correlationTool.vectorLayer"
    },
    plotType: {
      type: String,
      default: "linear"
    }
  },
  data: function() {
    return {
      dialog: false,
      fullscreen: false,
      config: {
        displaylogo: false
      },
      layout: {
        showLegend: true,
        height: 500,
        width: 850,
        xaxis: {
          title: {
            text: this.$t(this.labelX)
          },
          type: this.plotType
        },
        yaxis: {
          title: {
            text: this.$t(this.labelY)
          },
          type: this.plotType
        }
      }
    };
  },
  computed: {
    correlationValues() {
      return {
        x: this.x,
        y: this.y,
        mode: "markers",
        name: this.$t("entries")
      };
    },
    fittingPlot() {
      let x = this.x;
      if (x.length > 0) {
        let maxValue = parseInt(Math.max(...this.x));
        maxValue += (maxValue / 100) * 5; // extend x by 5%
        x = [...Array(parseInt(maxValue)).keys()];
      }

      return {
        x: x,
        y: x.map(this.calcFittingLine),
        mode: "lines",
        name: this.$t("correlationTool.plotTitle")
      };
    },
    data() {
      return [this.correlationValues, this.fittingPlot];
    }
  },
  methods: {
    calcFittingLine(val) {
      if (this.fittingAlgorithm === "exponential") {
        return this.coefficients[0] * Math.exp(this.coefficients[1] * val);
      } else if (this.fittingAlgorithm === "power") {
        return this.coefficients[0] * Math.pow(val, this.coefficients[1]);
      } else {
        const reducer = (accumulator, currentValue, index) =>
          accumulator + currentValue * val ** index;
        return this.coefficients.reduce(reducer);
      }
    },
    saveCorrelation() {
      this.$emit("saveCorrelation", true);
    },
    exportPlotData() {
      this.$emit("exportData");
    }
  }
};
</script>

<style scoped>
#close-btn {
  z-index: 1;
}
</style>
