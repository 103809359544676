<template>
  <v-dialog v-model="dialog" width="auto" max-width="900">
    <!--    <template v-slot:activator="{ on, attrs }">-->
    <!--      <v-btn class="ml-10 mt-5" color="primary" v-bind="attrs" v-on="on" :disabled="disabled">-->
    <!--        {{ $t("sedimentTool.ShowResultPlot") }}-->
    <!--      </v-btn>-->
    <!--    </template>-->
    <v-card>
      <v-card-title>{{ $t("sedimentTool.sedimentFlow") }}</v-card-title>
      <v-card-text>
        <plotly
          :data="data"
          :layout="layout"
          :display-mode-bar="false"
        ></plotly>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-btn text color="primary" @click="exportSedimentData">
          <v-progress-circular
            indeterminate
            color="accent"
            v-if="exportDataLoading"
            size="15"
            width="2"
          />
          {{ $t("exportPlotData") }}
        </v-btn>
        <v-spacer />
        <v-btn class="mr-2" text color="primary" @click="closeDialog">
          {{ $t("close") }}
        </v-btn>

        <v-dialog v-model="saveDialog" width="700">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              v-bind="attrs"
              v-on="on"
              :disabled="savingSediments"
            >
              {{ $t("save") }}
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              {{ $t("sedimentTool.sedimentFlow") }}
            </v-card-title>
            <v-card-text>
              <v-form v-model="validName" ref="form" lazy-validation>
                <v-text-field
                  :label="$t('correlationTool.nameRequired')"
                  :rules="nameRules"
                  v-model="stationName"
                ></v-text-field>
              </v-form>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                @click="saveSedimentData"
                v-if="!savingSediments"
              >
                {{ $t("save") }}
              </v-btn>
              <v-progress-circular
                indeterminate
                color="primary"
                v-else
                size="15"
                width="2"
              />
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { Plotly } from "vue-plotly";
import { mapActions, mapGetters } from "vuex";
import axios from "axios";
import Url from "@/core/services/url.services";
import ReportUtils from "@/core/utils/report.utils";

export default {
  name: "SedimentPlot",
  components: {
    Plotly
  },
  props: {
    disabled: {
      type: Boolean
    },
    result: {
      type: Object
    },
    dialog: {
      type: Boolean
    }
  },
  data: function() {
    return {
      exportDataLoading: false,
      savingSediments: false,
      saveDialog: false,
      fullscreen: false,
      config: {
        displaylogo: false
      },
      layout: {
        showLegend: true,
        height: 500,
        width: 850,
        xaxis: {
          title: {
            text: this.$t("datetime")
          }
        },
        yaxis: {
          title: {
            text: this.$t("sedimentTool.sedimentFlow")
          }
        }
      },
      stationName: "",
      validName: false,
      nameRules: [v => !!v || this.$t("correlationTool.nameRequired")]
    };
  },
  computed: {
    ...mapGetters("management", ["activeRegion"]),
    data() {
      return [{ x: this.result.x, y: this.result.y, type: "scatter" }];
    }
  },
  methods: {
    ...mapActions("vector", ["fetchVectorLayer"]),
    ...mapActions("analyse", ["fetchSedimentStations"]),
    ...mapActions("app", ["showSnackbar"]),
    closeDialog() {
      this.$emit("showPlot", false);
    },
    createPlot() {
      Plotly.newPlot("dataPlot", this.data, this.layout, this.config);
    },
    updatePlot() {
      Plotly.react("dataPlot", this.data, this.layout);
    },
    saveSedimentData() {
      this.validate();
      if (this.validName) {
        const regionId = this.activeRegion.id;
        let data = this.result;
        data.client = this.$appConfig.keycloakClient;
        data.region = regionId;
        data.name = this.stationName;
        this.savingSediments = true;
        axios
          .post(Url.sediment, data)
          .then(() => {
            this.fetchVectorLayer(regionId);
            this.showSnackbar({
              show: true,
              message: this.$t("sedimentTool.sedimentSaved"),
              color: "success"
            });
            this.fetchSedimentStations();
            this.saveDialog = false;
          })
          .catch(() => {
            this.showSnackbar({
              show: true,
              message: this.$t("errorMessage"),
              color: "error"
            });
          });
      }
    },
    validate() {
      this.$refs.form.validate();
    },
    exportSedimentData() {
      this.exportDataLoading = true;
      let data = ReportUtils.createBody(this.data);

      let csvContent =
        "data:text/csv;charset=utf-8," +
        "Datetime,Sediment Flux [tons/day]" +
        "\n" +
        data.map(e => e.join(";")).join("\n");

      let encodedUri = encodeURI(csvContent);
      let link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "sediment_data.csv");
      document.body.appendChild(link);
      link.click();
      this.exportDataLoading = false;
    }
  },
  watch: {
    result() {
      this.savingSediments = false;
    }
  }
};
</script>

<style scoped>
#close-btn {
  z-index: 1;
}
</style>
