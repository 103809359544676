<template>
  <v-tooltip left>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        id="showTimelapse-button"
        tile
        absolute
        top
        :style="{ 'background-color': showTimelapse ? 'grey' : 'white' }"
        @click="toggleTimelapse()"
        right
        fab
        small
        v-on="on"
        v-bind="attrs"
        class="showTimelapse-button"
      >
        <v-icon v-if="!showTimelapse" color="grey darken-3"
          >mdi-clock-fast</v-icon
        >
        <v-icon v-else color="white">mdi-clock-fast</v-icon>
      </v-btn>
    </template>
    <span v-if="!showTimelapse">{{ $t("ShowTimelapse") }}</span>
    <span v-else>{{ $t("HideTimelapse") }}</span>
  </v-tooltip>
</template>

<script>
export default {
  name: "ShowTimelapse",
  data: () => ({
    showTimelapse: false
  }),
  methods: {
    toggleTimelapse() {
      this.showTimelapse = !this.showTimelapse;
      this.$emit("toggleTimelapseFunktion");
    }
  }
};
</script>

<style>
.showTimelapse-button {
  margin-top: 240px;
}
</style>
