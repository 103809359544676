<template>
  <v-main>
    <div id="main-window">
      <div id="visualize-map" data-tap-disabled="true">
        <div>
          <v-btn class="menu-btn" icon style="background-color: white">
            <v-icon
              v-if="showMenu"
              @click.stop="$store.dispatch('app/showMenu', !showMenu)"
              >mdi-chevron-left
            </v-icon>
            <v-icon
              v-else
              @click.stop="$store.dispatch('app/showMenu', !showMenu)"
              >mdi-chevron-right
            </v-icon>
          </v-btn>
        </div>
        <right-side-menu
          v-if="!mapIsLoading"
          :map="map"
          :place="place"
          :basemap-layer="basemapLayer"
          @toggleTimelapse="showTimelapse = !showTimelapse"
        ></right-side-menu>
        <v-card
          width="260"
          :height="getWindowHeight()"
          elevation="0"
          v-if="this.activeRasterLayer != null"
          class="meta-data-image "
        >
          <div
            class="justify-end mr-5 mt-2"
            v-if="activeRasterLayer.resolution"
          >
            <v-row class="ml-8">
              <v-spacer></v-spacer>
              <v-icon v-if="!hideWindow" @click="hideWindow = !hideWindow"
                >mdi-chevron-down
              </v-icon>
              <v-icon v-else @click="hideWindow = !hideWindow"
                >mdi-chevron-up
              </v-icon>
            </v-row>
          </div>
          <div class="justify-end mr-5" v-if="activeRasterLayer.resolution">
            <v-row class="ml-8">
              <v-col class="pb-1">{{ $t("metaData.sensor") }}:</v-col>
              <v-col class="pb-1 pl-0">{{ activeSensor }}</v-col>
            </v-row>
            <v-row class="ml-8 mb-2">
              <v-col class="pb-1"> {{ $t("metaData.resolution") }}:</v-col>
              <v-col
                class="pb-1"
                v-if="
                  activeRasterLayer.sensor == 'DRONE' ||
                    activeRasterLayer.sensor == 'modelled'
                "
              >
                ~{{ activeRasterLayer.resolution.toFixed(2) }} m
              </v-col>
              <v-col class="pb-1" v-else>
                ~{{ activeRasterLayer.resolution.toFixed(0) }} m
              </v-col>
              <metadata-popup></metadata-popup>
            </v-row>
          </div>
          <img
            alt="legend"
            v-if="legendImage"
            class="legend-image"
            :src="legendImage"
          />
        </v-card>
        <div
          v-if="showTimelapse && activeRasterLayer && layerTimesteps.length"
          class="map-overlay top"
        >
          <timelab-slider
            @changeTimeStep="changeTimeStep"
            :active-raster-layer="activeRasterLayer"
            :sortlayer-timesteps="layerTimesteps"
            :play-option="true"
          ></timelab-slider>
        </div>
        <div
          v-if="cursorPosition"
          class="font-weight-light cursor-position"
          style="font-size: 84%"
        >
          {{ cursorPosition }}
        </div>
      </div>
      <bottom-plot
        v-if="showPlot"
        @changeTimeStep="changeTimestepPlot"
      ></bottom-plot>
      <bottom-plot-transect v-if="showTransectPlot"></bottom-plot-transect>
      <bottom-profile-plot v-if="showProfilePlot"></bottom-profile-plot>
      <coasts-stations-bottom-plot
        v-if="showStationPlot"
      ></coasts-stations-bottom-plot>
      <data-plot-popup />
    </div>
  </v-main>
</template>

<script>
import regionMixin from "@/core/mixins/region.mixin";
import bbox from "@turf/bbox";
import mapbox from "mapbox-gl";
import MapboxConfig from "@/core/plugins/mapbox";
import MapboxUtils from "mapbox-gl-utils";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import StaticMode from "@mapbox/mapbox-gl-draw-static-mode";
import { mapActions, mapState } from "vuex";
import BottomPlot from "@/core/components/plot/BottomPlot.vue";
import DataPlotPopup from "@/core/components/plot/DataPlotPopup.vue";
import BottomPlotTransect from "@/core/components/plot/BottomPlotTransect.vue";
import MetadataPopup from "@/core/components/map/pop-up/MetadataPopup.vue";
import BottomProfilePlot from "@/core/components/plot/CoastsBottomProfilePlot.vue";
import mapboxMixins from "@/core/mixins/mapbox.mixins";
import TimelabSlider from "@/core/components/map/TimelabSlider.vue";
import coastLayerMixin from "@/core/mixins/coastLayer.mixin";
import CoastsStationsBottomPlot from "@/core/components/plot/CoastsStationsBottomPlot.vue";
import RightSideMenu from "@/core/components/map/RightSideMenu.vue";
import rasterLayerMixin from "@/core/mixins/rasterLayer.mixin";
import SensorUtils from "@/core/utils/sensor.utils";
import popUpMixin from "@/core/mixins/popUp.mixin";

export default {
  name: "MapView",
  mixins: [
    regionMixin,
    mapboxMixins,
    coastLayerMixin,
    rasterLayerMixin,
    popUpMixin
  ],
  components: {
    RightSideMenu,
    CoastsStationsBottomPlot,
    TimelabSlider,
    BottomProfilePlot,
    MetadataPopup,
    BottomPlotTransect,
    DataPlotPopup,
    BottomPlot
  },
  props: {
    place: { type: String, default: "visualize" }
  },
  data: () => ({
    mapIsLoading: true,
    map: null,
    baseUrl: process.env.VUE_APP_BASE_URL,
    showTimelapse: false,
    activeSensor: null,
    legendImage: null,
    cursorPosition: null,
    hideWindow: false
  }),
  methods: {
    ...mapActions("app", ["setVisMapLoaded"]),
    ...mapActions("app", ["showSnackbar"]),
    ...mapActions("plot", [
      "resetDataCache",
      "setShowTransectPlot",
      "setShowPlotFeature"
    ]),
    ...mapActions("coastsPlot", ["setShowProfilePlot", "setShowStationPlot"]),
    // ...mapActions("raster", ["setShowMetaDataWindow"]),
    ...mapActions("queryParams", ["setLat", "setLng", "setZoom"]),
    initMap() {
      mapbox.accessToken = MapboxConfig.accessToken;
      const { lat, lng, zoom } = this.$route.query;
      const mapOptions = {
        container: "visualize-map",
        style: this.mapStyle,
        transformRequest: url => {
          if (url.startsWith(this.baseUrl) && this.$keycloak.authenticated) {
            return {
              url: url,
              headers: { Authorization: `Bearer ${this.$keycloak.token}` }
            };
          }
        }
      };
      if (lat && lng && zoom) {
        mapOptions.center = this.getCenter();
        mapOptions.zoom = this.getZoom();
      } else {
        mapOptions.bounds = this.boundingBox;
      }
      this.map = new mapbox.Map(mapOptions);

      const scaleControl = new mapbox.ScaleControl({
        maxWidth: 150,
        unit: "metric"
      });
      this.map.addControl(scaleControl, "bottom-right");
      this.updateURL();
      this.map.on("load", () => {
        MapboxUtils.init(this.map, mapbox);
        this.setVisMapLoaded(true);
        this.onMouseMove(this.map);
        this.addDrawComponent(this.map);
        this.$emit("mapLoaded", this.map);
        setTimeout(() => {
          this.mapIsLoading = false;
        }, 1000);
      });
      this.$store.visualizeMap = this.map;
      this.map.on("moveend", this.updateURL);
    },
    async setLegendImage(layerId, layerStep) {
      this.legendImage = `${process.env.VUE_APP_RASTLESS_URL}/legend?layer_id=${layerId}&layer_step=${layerStep}&orientation=horizontal`;
    },
    changeTimestepPlot(timestep) {
      this.$store.dispatch("raster/setSelectedPlotStep", timestep);
    },

    changeTimeStep(step) {
      if (this.activeRasterLayer.layerId.length && step !== null) {
        const selectedDatetime = this.layerTimesteps[step];
        this.addLayers(
          this.activeRasterLayer.layerId,
          selectedDatetime,
          this.accessToken,
          "visualize"
        );
        let activeLayerInfo = {
          ...this.activeRasterLayer
        };
        activeLayerInfo.datetime = selectedDatetime.datetime;
        activeLayerInfo.sensor = selectedDatetime.sensor;
        this.$store.dispatch("raster/setActiveLayer", activeLayerInfo);
      }
    },
    onMouseMove(map) {
      map.on("mousemove", e => {
        this.cursorPosition = `${e.lngLat.lat.toFixed(
          4
        )} ${e.lngLat.lng.toFixed(4)}`;
      });
    },
    addDrawComponent(map) {
      const modes = MapboxDraw.modes;
      modes.static = StaticMode;
      let draw = new MapboxDraw({
        displayControlsDefault: false,
        modes: modes
      });
      this.$store.draw = draw;
      map.addControl(draw);
      map.getCanvas().style.cursor = "pointer";
      draw.changeMode("static");
      return draw;
    },
    getWindowHeight() {
      if (!this.hideWindow) {
        if (this.activeRasterLayer.resolution && this.legendImage) {
          return 165;
        } else if (!this.activeRasterLayer.resolution && this.legendImage) {
          return 95;
        } else if (this.activeRasterLayer.resolution && !this.legendImage) {
          return 95;
        } else {
          return;
        }
      } else {
        return 25;
      }
    },
    getCenter() {
      const { lat, lng } = this.$route.query;
      return [lng ? parseFloat(lng) : 0, lat ? parseFloat(lat) : 0];
    },
    getZoom() {
      const { zoom } = this.$route.query;
      return zoom ? parseFloat(zoom) : 2;
    },
    updateURL() {
      const center = this.map.getCenter();
      const zoom = this.map.getZoom();
      this.setLat(center.lat.toFixed(6));
      this.setLng(center.lng.toFixed(6));
      this.setZoom(zoom.toFixed(2));
      const newQuery = {
        lat: this.lat,
        lng: this.lng,
        zoom: this.zoom,
        layer: this.layer,
        timestep: this.timestep
      };
      const resolvedUrl = this.$router.resolve({ query: newQuery }).href;
      window.history.replaceState({}, "", resolvedUrl);
    }
  },
  computed: {
    ...mapState("app", ["showMenu"]),
    ...mapState("plot", ["showTransectPlot", "showPlotFeature", "showPlot"]),
    ...mapState("coastsPlot", ["showProfilePlot", "showStationPlot"]),
    ...mapState("raster", [
      "activeRasterLayer",
      "showMetaDataWindow",
      "layerTimesteps"
    ]),
    ...mapState("management", ["tempResolution"]),
    ...mapState("queryParams", ["layer", "timestep", "lat", "lng", "zoom"]),
    sensorNames() {
      return SensorUtils.getSensorDict();
    },
    mapStyle() {
      return this.getMapStyle();
    },
    boundingBox() {
      if (this.activeRegion) {
        return bbox(this.activeRegion);
      } else {
        return null;
      }
    },
    basemapLayer() {
      if (this.$appConfig.keycloakClient === "coasts") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.mapStyle.layers[2].layout.visibility = "visible";
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.mapStyle.layers[0].layout.visibility = "none";
      }
      return this.mapStyle.layers.filter(item => item.id.startsWith("basemap"));
    }
  },
  watch: {
    "$route.params.regionId": {
      handler() {
        this.setVisMapLoaded(false);
        this.resetDataCache();
        this.setShowProfilePlot(false);
        this.setShowStationPlot(false);
        this.setShowTransectPlot(false);
      },
      immediate: true
    },
    showTransectPlot() {
      window.setTimeout(() => this.map.resize(), 100);
    },
    showPlot() {
      window.setTimeout(() => this.map.resize(), 100);
    },
    showProfilePlot() {
      window.setTimeout(() => this.map.resize(), 100);
    },
    showStationPlot() {
      window.setTimeout(() => this.map.resize(), 100);
    },
    showPlotFeature() {
      if (
        this.showPlotFeature &&
        !this.showStationPlot &&
        !this.showTransectPlot &&
        !this.showProfilePlot &&
        !this.showPlot
      ) {
        this.showSnackbar({
          show: true,
          message: this.$t("showNoPlotMessage"),
          color: "error",
          timeout: 5000
        });
        this.setShowPlotFeature(false);
      }
      window.setTimeout(() => this.map.resize(), 100);
    },
    activeRasterLayer() {
      if (this.activeRasterLayer?.layerId) {
        const activeSensor = this.sensorNames.filter(
          object => object.val == this.activeRasterLayer.sensor
        );
        if (activeSensor != null && this.tempResolution == "all") {
          this.activeSensor = activeSensor[0].label;
        } else {
          this.activeSensor = this.activeRasterLayer.sensor;
        }
      }
    },
    "activeRasterLayer.colormap": {
      handler(cmapName) {
        if (cmapName) {
          this.setLegendImage(
            this.activeRasterLayer.layerId,
            this.activeRasterLayer.datetime
          );
        } else {
          this.legendImage = null;
        }
      },
      immediate: true
    }
  },
  mounted() {
    this.$watch(
      () => ({
        lat: this.lat,
        lng: this.lng,
        zoom: this.zoom,
        layer: this.layer,
        timestep: this.timestep
      }),
      () => {
        this.updateURL();
      },
      { deep: true }
    );
    this.$watch(
      "boundingBox",
      bbox => {
        if (bbox) {
          this.initMap();
        }
      },
      {
        immediate: true
      }
    );
  },
  beforeDestroy() {
    this.setVisMapLoaded(false);
    this.resetDataCache();
    this.setShowProfilePlot(false);
    this.setShowStationPlot(false);
    this.setShowTransectPlot(false);
  }
};
</script>

<style scoped>
#main-window {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

#visualize-map {
  width: 100%;
  height: 100%;
}

.legend-image {
  width: 240px;
  padding: 5px;
  z-index: 2;
}

.meta-data-image {
  position: absolute;
  bottom: 0px;
  left: 0px;
  border-radius: 0 0 0 0;
  background-color: rgba(250, 250, 250, 0.5);
  padding: 5px;
  z-index: 2;
}

.cursor-position {
  z-index: 2;
  position: absolute;
  bottom: 0px;
  right: 280px;
  background-color: hsla(0, 0%, 100%, 0.5);
  width: 100px;
  padding-left: 6px;
}

.map-overlay {
  z-index: 2;
  font: 12px/20px "Helvetica Neue", Arial, Helvetica, sans-serif;
  position: absolute;
  width: 40%;
  bottom: 00px;
  left: 260px;
  margin: 0px;
  padding: 0px;
}

.map-overlay .map-overlay-inner {
  background-color: rgba(250, 250, 250, 0.5) !important;
  z-index: 2;
  padding: 10px;
}

.map-overlay input {
  z-index: 2;
  background-color: transparent;
  display: inline-block;
  width: 100%;
  position: relative;
  margin: 0;
  cursor: ew-resize;
}

.menu-btn {
  position: absolute;
  top: 0px;
  left: 0px;
  border-radius: 0 0 0 0;
  background-color: rgba(250, 250, 250, 0.5);
  padding: 5px;
  z-index: 2;
}
</style>
