<template>
  <v-list-item>
    <v-list-item-content>
      <v-select
        :disabled="disableRegionMenu"
        :value="activeRegion"
        :items="regionObjects"
        :label="$t('selectedRegion')"
        return-object
        :item-text="getRegionName"
        @change="changeRegionRoute"
        hide-details
        :hint="showInfo ? $t('downloadView.selectScenesInfo') : ''"
        persistent-hint
      >
      </v-select>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "RegionSelectionSmall",
  props: {
    disableRegionMenu: {
      type: Boolean,
      default: false
    },
    showInfo: Boolean
  },
  computed: {
    ...mapState("management", ["regions"]),
    regionObjects() {
      return this.regions.features;
    },
    activeRegion() {
      return this.getActiveRegion(this.regions, this.$route.params.regionId);
    }
  },
  methods: {
    ...mapActions("dashboard", [
      "setAllData",
      "setSelectedStartDate",
      "setSelectedEndDate"
    ]),
    ...mapActions("download", ["updateIsLoading"]),

    getActiveRegion(regions, regionId) {
      return regions.features.find(x => x.id === parseInt(regionId));
    },
    resetActiveRegion() {
      this.$router.push({ name: "Regions" });
    },
    getRegionName(item) {
      let regionName = item.properties.name;
      if (item.properties?.country_code) {
        regionName = `${regionName}, ${item.properties.country_code}`;
      }
      return regionName;
    },
    changeRegionRoute(item) {
      this.setAllData(false);
      this.setSelectedStartDate(null);
      this.setSelectedEndDate(null);

      this.$router.push({
        name: this.$route.name,
        params: { regionId: item.id }
      });

      this.updateIsLoading(true);
    }
  }
};
</script>

<style scoped></style>
