<template>
  <div>
    <v-tooltip left>
      <template #activator="{ on: onTooltip }">
        <v-btn
          id="analysis-switch"
          tile
          absolute
          top
          right
          :style="{ 'background-color': analysisStation ? 'grey' : 'white' }"
          fab
          small
          v-on="{ ...onTooltip }"
          class="btn-fix"
          @click="analysisStation = !analysisStation"
        >
          <v-icon v-if="!analysisStation" color="grey darken-3"
            >mdi-map-marker-radius
          </v-icon>
          <v-icon v-else color="white">mdi-map-marker-radius</v-icon>
        </v-btn>
      </template>

      <span v-if="!analysisStation">{{ $t("AnalysisStation") }}</span>
    </v-tooltip>
    <analysis-station
      v-if="analysisStation"
      :place="place"
      :map="map"
      @changeMode="changeEditMode"
    ></analysis-station>
    <basemap-switch
      @toggleAnalysisStation="analysisStation = false"
      :map="map"
      :layers="basemapLayer"
    />
    <layer-switch @toggleAnalysisStation="analysisStation = false" :map="map" />
    <show-plot></show-plot>
    <show-timelapse
      v-if="$appConfig.keycloakClient === 'coasts'"
      @toggleTimelapseFunktion="$emit('toggleTimelapse')"
    ></show-timelapse>
    <v-tooltip left>
      <template #activator="{ on: onTooltip }">
        <v-btn
          v-if="$appConfig.keycloakClient === 'coasts'"
          id="layer-zoom"
          tile
          absolute
          top
          right
          fab
          small
          v-on="{ ...onTooltip }"
          class="layer-zoom"
          @click="zoomToLayer"
        >
          <v-icon color="grey darken-3">mdi-crosshairs</v-icon>
        </v-btn>
        <v-btn
          v-else
          id="timestep-zoom"
          tile
          :disabled="tempResolution != 'all'"
          absolute
          top
          right
          fab
          small
          v-on="{ ...onTooltip }"
          class="layer-zoom"
          @click="
            zoomToTimestep(activeRasterLayer.layerId, selectedStep.datetime)
          "
        >
          <v-icon color="grey darken-3">mdi-crosshairs</v-icon>
        </v-btn>
      </template>

      <span v-if="$appConfig.keycloakClient === 'coasts'">{{
        $t("zoomToLayer")
      }}</span>
      <span v-else>{{ $t("zoomToTimestep") }}</span>
    </v-tooltip>
  </div>
</template>
<script>
import BasemapSwitch from "@/core/components/map/RightSideMenu/BasemapSwitch.vue";
import LayerSwitch from "@/core/components/map/RightSideMenu/LayerSwitch.vue";
import AnalysisStation from "@/core/components/map/RightSideMenu/AnalysisStation.vue";
import { mapActions, mapGetters, mapState } from "vuex";
import virtualStationService from "@/core/services/virtualStation.services";
import popUpMixin from "@/core/mixins/popUp.mixin";
import ShowTimelapse from "@/core/components/map/RightSideMenu/ShowTimelapse.vue";
import ShowPlot from "@/core/components/map/RightSideMenu/ShowPlot.vue";
import coastLayerMixin from "@/core/mixins/coastLayer.mixin";
import rasterLayerMixin from "@/core/mixins/rasterLayer.mixin";
import axios from "axios";

export default {
  name: "RightSideMenu",
  components: {
    ShowPlot,
    ShowTimelapse,
    AnalysisStation,
    LayerSwitch,
    BasemapSwitch
  },
  props: {
    map: Object,
    basemapLayer: Array,
    place: String
  },
  mixins: [popUpMixin, coastLayerMixin, rasterLayerMixin],
  data: () => ({
    analysisStation: true
  }),
  computed: {
    ...mapState("draw", ["selectedFeatureId"]),
    ...mapGetters("draw", ["stationById"]),
    ...mapState("raster", ["selectedStep", "activeRasterLayer"]),
    ...mapState("management", ["tempResolution"])
  },
  methods: {
    ...mapActions("draw", ["setSelectedFeatureId", "addVirtualStation"]),
    ...mapActions("app", ["showSnackbar"]),
    ...mapActions("draw", [
      "setSelectedFeatureId",
      "updateName",
      "addPermanentVirtualStations",
      "addVirtualStation"
    ]),
    drawCreatedEvent() {
      this.map.on("draw.create", entry => {
        this.addVirtualStation(entry.features[0]);
        this.showNamingPopup(entry);
        this.$emit("toggleSnackbar");
      });
    },
    drawSelectionEvent() {
      this.map.on("draw.selectionchange", e => {
        if (e.features.length) {
          this.setSelectedFeatureId(e.features[0].id);
        } else {
          this.setSelectedFeatureId(null);
        }
      });
    },
    drawUpdatedEvent() {
      this.map.on("draw.update", e => {
        const id = e.features[0].id;
        const virtualStation = this.stationById(id);
        if (virtualStation.permanent) {
          try {
            virtualStationService.update(id);
            this.showSnackbar({
              show: true,
              message: this.$t("stationUpdated"),
              color: "success"
            });
          } catch (e) {
            this.showSnackbar({
              show: true,
              message: this.$t("errorMessage"),
              color: "error"
            });
          }
        }
      });
    },
    changeEditMode(value) {
      if (value) {
        if (this.$options.popUp) {
          this.$options.popUp.remove();
        }
        this.map.off("click", this.showPopup);
        this.map.off("touchend", this.showPopup);
        this.map.getCanvas().style.cursor = "crosshair";
      } else {
        this.setSelectedFeatureId(null);
        this.map.on("click", this.showPopup);
        this.map.on("touchend", this.showPopup);
        this.map.getCanvas().style.cursor = "pointer";
      }
    },
    async fetchPermanentVirtualStations() {
      try {
        const response = await axios.get(
          `/userdata/virtual-stations/?region=${this.$route.params.regionId}`
        );
        let stationArray = response.data;
        if (
          this.$appConfig.keycloakClient === "coasts" &&
          this.place !== "forecast"
        ) {
          stationArray = stationArray.filter(
            station => !station.name.includes("Profile")
          );
        }
        stationArray = stationArray.sort((a, b) =>
          a.name.toUpperCase().localeCompare(b.name.toUpperCase())
        );
        this.addPermanentStationsToMap(stationArray);
        this.addPermanentVirtualStations(stationArray);
      } catch {
        this.showSnackbar({
          show: true,
          message: this.$t("errorMessage"),
          color: "error"
        });
      }
    },
    addPermanentStationsToMap(stationArray) {
      stationArray.forEach(station => {
        const feature = {
          id: station.id,
          type: "Feature",
          properties: {},
          geometry: station.geometry
        };
        this.$store.draw.add(feature);
      });
    }
  },
  created() {
    this.drawCreatedEvent();
    this.drawSelectionEvent();
    this.drawUpdatedEvent();
    this.setSelectedFeatureId(null);
    this.map.on("click", this.showPopup);
    this.map.on("touchend", this.showPopup);
    if (this.$keycloak.authenticated) {
      this.fetchPermanentVirtualStations();
    }
  }
};
</script>
<style scoped>
#analysis-switch {
  margin-top: 40px;
}

#layer-zoom {
  margin-top: 290px;
}

#timestep-zoom {
  margin-top: 240px;
}

.btn-fix:focus::before {
  opacity: 0 !important;
}

.btn-fix:hover::before {
  opacity: 0.08 !important;
}
</style>
