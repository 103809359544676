import { render, staticRenderFns } from "./Sediment.vue?vue&type=template&id=bcf0a4e4&scoped=true"
import script from "./Sediment.vue?vue&type=script&lang=js"
export * from "./Sediment.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bcf0a4e4",
  null
  
)

export default component.exports