<template>
  <v-menu v-model="showMenu" :close-on-content-click="false" offset-x>
    <template v-slot:activator="{ on }">
      <v-icon :title="$t('deleteVirtualStation')" small class="mr-2" v-on="on">
        mdi-delete
      </v-icon>
    </template>
    <v-card>
      <v-card-title class="pb-0"
        >{{ $t("confirmVirtualStationDeletion") }}
      </v-card-title>
      <v-card-text>
        <v-text-field
          v-model="name"
          placeholder="Name"
          hide-details
          disabled
        ></v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="cancel">
          {{ $t("cancel") }}
        </v-btn>
        <v-btn color="primary" text @click="deleteStation">
          {{ $t("delete") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  name: "DeleteVirtualStationMenu",
  props: ["item"],
  data: function() {
    return {
      showMenu: false
    };
  },
  computed: {
    name() {
      return this.item.name;
    }
  },
  methods: {
    cancel() {
      this.showMenu = false;
      this.name = this.item.name;
    },
    deleteStation() {
      this.$emit("delete-station", this.item);
      this.showMenu = false;
    }
  }
};
</script>

<style scoped></style>
