<template>
  <v-row>
    <v-spacer />
    <v-btn
      v-if="showAddOrder"
      text
      color="#757575"
      class="ma-4 mt-0"
      small
      @click="navigateToOrder"
      :title="$t('order.addDataToOrder')"
    >
      <v-icon class="pb-1 pl-1 pt-1" dense> mdi-plus-circle</v-icon>
      {{ $t("order.orderNewData") }}
    </v-btn>
  </v-row>
</template>

<script>
import Vue from "vue";
import { mapGetters } from "vuex";

export default {
  name: "RequestDataForRegion",
  data() {
    return {
      showAddOrder: false
    };
  },
  computed: {
    ...mapGetters("management", ["activeRegion"]),
    regionId() {
      return this.$route.params.regionId;
    }
  },
  methods: {
    async navigateToOrder() {
      this.$router.push({
        name: "RegionOrder",
        params: { regionId: this.regionId }
      });
    },

    showAddOrderButton() {
      const client = Vue.prototype.$appConfig.keycloakClient;
      const roles = this.$keycloak.resourceAccess?.[client]?.roles;
      return !!(
        (roles && roles.includes("admin")) ||
        this.activeRegion.properties.owner
      );
    }
  },
  watch: {
    async activeRegion() {
      this.showAddOrder = this.showAddOrderButton();
    }
  },
  async created() {
    this.showAddOrder = this.showAddOrderButton();
  }
};
</script>

<style scoped></style>
