<template>
  <div class="pr-5">
    <v-row>
      <v-col cols="auto" class="pr-0 mb-0 pl-0">
        <span class="font-weight-bold">
          {{ $t("sedimentTool.calculateSedimentFlow") }} =
        </span>
        <v-avatar color="grey" size="30">
          A
        </v-avatar>
        *
        <v-avatar color="grey" size="30">
          B
        </v-avatar>
      </v-col>
      <v-col>
        <title-description-info
          :title="$t('sedimentTool.toolName')"
          :description="$t('infobox.sedFlowCalcDescription')"
        />
      </v-col>
      <v-spacer />
      <v-col class="pt-2">
        <v-btn
          text
          color="primary"
          :to="{
            name: 'SedimentResults',
            params: { regionId: $route.params.regionId }
          }"
          >Show functions<v-icon>mdi-function-variant</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <p class="mt-5 mr-2">
        <v-avatar color="grey" size="30">
          A
        </v-avatar>
        {{ $t("correlationToolDischarge.dischargeData") }}
      </p>
      <title-description-info
        :title="$t('correlationToolDischarge.dischargeData')"
        :description="$t('infobox.sedflowDescription')"
      />
    </v-row>
    <v-select
      :label="$t('correlationTool.vectorLayer')"
      :items="vectorLayer"
      v-model="selectedVectorLayer"
      return-object
      item-value="id"
      item-text="name"
      class="pt-2 ml-6"
      @change="fetchGeometryData"
      dense
      style="width: 80%"
    />
    <v-select
      :label="$t('correlationTool.vectorLayerParam')"
      :items="parameterItems"
      v-model="selectedVectorParameter"
      item-value="id"
      item-text="name"
      dense
      return-object
      class="pt-2 ml-6"
      style="width: 80%"
    />
    <v-autocomplete
      v-model="selectedInSituStation"
      :items="inSituItems"
      :loading="inSituItemsLoading"
      item-text="name"
      return-object
      :label="$t('correlationTool.inSituStation')"
      dense
      class="pb-2 ml-6"
      style="width: 80%"
    />
    <v-row>
      <p class="mt-5 mr-2">
        <v-avatar color="grey" size="30">
          B
        </v-avatar>
        {{ $t("sedimentRateTool.sedimentConcentration") }}
      </p>
      <title-description-info
        :title="$t('correlationToolDischarge.dischargeData')"
        :description="$t('infobox.sedflowDescription')"
      />
    </v-row>
    <v-autocomplete
      :items="correlationDischargeFormulaItems"
      item-value="id"
      item-text="name"
      v-model="selectedCorrelationDischargeFormula"
      dense
      outlined
      color="primary"
      :label="$t('correlationTool.formula')"
      :loading="loadCorrelationDischargeFormulaItems"
      class="pt-2 ml-6"
      style="width: 80%"
    >
      <template slot="item" slot-scope="data">
        <span class="pr-4">{{ data.item.name }}</span>
        <span
          v-if="data.item.fitting_algorithm === 'exponential'"
          v-html="exponentialFormulaLabel(data.item.coefficients)"
          style="font-size: 12px"
        />
        <span
          v-else
          v-html="linearFormulaLabel(data.item.coefficients)"
          style="font-size: 12px"
        />
      </template>
    </v-autocomplete>
    <v-row align="center" style="width: 80%">
      <v-divider class="my-4 ml-6"></v-divider>
      {{ $t("or") }}
      <v-divider></v-divider>
    </v-row>
    <v-row class="py-4 mx-2 justify-center" style="width: 78%">
      <v-btn
        color="primary"
        text
        outlined
        @click="showSaveDialog = true"
        width="280px"
      >
        {{ $t("sedimentTool.defineNewFormula") }}
      </v-btn>
      <v-spacer style="max-width: 10%" class="hidden-md-and-down" />
      <correlation-discharge-input-data></correlation-discharge-input-data>
    </v-row>
    <save-correlation
      v-model="showSaveDialog"
      :input-coefficients="[1, 1]"
      :endpoint-url="endpointDischargeUrl"
    />
    <v-divider class="mt-3" />
    <v-divider />
    <v-row class="pt-3">
      <v-btn
        class="ml-10 mt-5"
        color="primary"
        @click="triggerCalculation"
        :disabled="!allowCalculation"
      >
        {{ $t("sedimentTool.ShowResultPlot") }}
        <v-icon class="pl-3" v-if="loadResults">mdi-spin mdi-loading</v-icon>
      </v-btn>
      <sediment-plot
        :dialog="showSedimentPlot"
        :disabled="!showPlotButton"
        :result="result"
        @showPlot="showSedimentPlot = false"
      ></sediment-plot>
    </v-row>
  </div>
</template>

<script>
import axios from "axios";
import Url from "@/core/services/url.services";
import { mapState, mapActions, mapGetters } from "vuex";
import analyseMixin from "@/core/mixins/analyse.mixin";
import SedimentPlot from "@/core/components/plot/SedimentPlot";
import SaveCorrelation from "./SaveCorrelation";
import TitleDescriptionInfo from "@/core/components/infos/TitleDescriptionInfo";
import CorrelationDischargeInputData from "@/core/components/menu/eotool/analyse/CorrelationDischargeInputData.vue";
import vectorLayerMixin from "@/core/mixins/vectorLayer.mixin";

export default {
  name: "SedimentTool",
  mixins: [analyseMixin, vectorLayerMixin],
  components: {
    CorrelationDischargeInputData,
    SedimentPlot,
    SaveCorrelation,
    TitleDescriptionInfo
  },
  methods: {
    ...mapActions("analyse", ["fetchCorrelationDischargeFormulas"]),
    ...mapActions("vector", ["setActiveVectorLayer", "fetchVectorLayer"]),
    async calculateSediment() {
      this.loadResults = true;
      try {
        const data = {
          formula: this.selectedCorrelationDischargeFormula,
          layer_geometry: this.selectedInSituStation.id,
          insitu_property: this.selectedVectorParameter.id
        };
        const response = await axios.post(Url.calculateCorrelationFlux, data);
        this.result = response.data;
        this.showSedimentPlot = true;
        this.loadResults = false;
      } catch (e) {
        console.log(e);
        this.result = null;
        this.showSedimentPlot = false;
        this.loadResults = false;
      }
    },
    triggerCalculation() {
      if (this.allowCalculation) {
        this.calculateSediment();
      }
    }
  },
  data: () => ({
    steps: 1,
    showSaveDialog: false,
    showSedimentPlot: false,
    loadResults: false,
    showPlot: false,
    selectedCorrelationDischargeFormula: null,
    selectedVectorLayer: null,
    selectedVectorParameter: null,
    selectedInSituStation: null,
    inSituItems: [],
    inSituItemsLoading: false,
    result: {
      x: [],
      y: []
    },
    showLabels: true,
    endpointDischargeUrl: Url.correlationDischarge
  }),
  computed: {
    ...mapGetters("management", ["activeRegion"]),
    ...mapState("vector", ["vectorLayer"]),
    ...mapState("analyse", [
      "correlationDischargeFormulaItems",
      "loadCorrelationDischargeFormulaItems"
    ]),
    dataWatcher() {
      return [
        this.selectedCorrelationDischargeFormula,
        this.selectedInSituStation,
        this.selectedVectorLayer,
        this.selectedVectorParameter
      ];
    },
    allowCalculation() {
      return this.dataWatcher.every(val => !!val);
    },
    showPlotButton() {
      return this.result.x.length > 0;
    }
  },
  created() {
    this.fetchCorrelationDischargeFormulas();
    this.fetchVectorLayer(this.activeRegion.id);
  },
  watch: {
    // dataWatcher() {
    //   if (this.allowCalculation) {
    //     this.calculateSediment();
    //   }
    // },
    selectedVectorLayer() {
      if (this.selectedVectorLayer && this.parameterItems.length) {
        this.selectedVectorParameter = this.parameterItems[0];
        const activeSelection = {
          layerId: this.selectedVectorLayer.id,
          layerName: this.selectedVectorLayer.name,
          parameterId: this.selectedVectorParameter.id,
          parameterName: this.selectedVectorParameter.name,
          unit: this.selectedVectorParameter.unit,
          startDate: this.startDate,
          endDate: this.endDate,
          style: this.selectedVectorLayer.style,
          shpType: this.selectedVectorLayer.shp_type,
          timestep: this.selectedTimestep
        };
        let layerRequestData = Object.assign({}, activeSelection);
        let layerPopupData = Object.assign({}, activeSelection);

        if (this.selectedVectorLayer.shp_type === "timesteps") {
          layerRequestData.startDate = this.selectedTimestep;
          layerRequestData.startDate = this.selectedTimestep;

          layerPopupData.startDate = this.minDate;
          layerPopupData.endDate = this.maxDate;
        }
        layerPopupData.style = activeSelection.style;

        this.$store.visualizeMap.U.removeSource("vectorSource");
        this.addVector(layerRequestData);
        // this.setActiveVectorLayer(layerPopupData);
        this.toggleLabels();
      }
    }
  }
};
</script>

<style scoped>
.expansion-panel-content::v-deep .v-expansion-panel-content__wrap {
  padding: 0 !important;
}
</style>
