<template>
  <v-dialog v-model="dialog" width="auto" max-width="700">
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="primary" text outlined v-bind="attrs" v-on="on">
        {{ $t("sedimentTool.calculatePortalData") }}
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="font-weight-regular">{{ cardTitle }}</v-card-title>
      <v-card-text class="pl-10">
        <v-row>
          <p class="font-weight-bold black--text mt-4 mr-2">
            {{ $t("correlationToolDischarge.dischargeData") }}
          </p>
          <title-description-info
            :title="$t('correlationToolDischarge.dischargeData')"
            :description="$t('infobox.dischargeDataDescription')"
          />
        </v-row>
        <v-select
          style="width: 70%"
          :label="$t('correlationTool.vectorLayer')"
          :items="vectorLayer"
          v-model="selectedVectorLayer"
          return-object
          item-value="id"
          item-text="name"
          class="pt-2"
          @change="fetchGeometryData"
          dense
        />
        <v-select
          style="width: 70%"
          :label="$t('correlationTool.vectorLayerParam')"
          :items="parameterItems"
          v-model="selectedVectorParameter"
          item-value="id"
          item-text="name"
          dense
        />
        <v-autocomplete
          style="width: 70%"
          v-model="selectedInSituStation"
          :items="inSituItems"
          :loading="inSituItemsLoading"
          item-text="name"
          return-object
          :label="$t('correlationTool.inSituStation')"
          dense
          class="pb-2"
        />
        <!--        <correlation-tool></correlation-tool>-->
        <v-row>
          <p class="font-weight-bold black--text mt-4 mr-2">
            {{ $t("correlationToolDischarge.sscData") }}
          </p>
          <title-description-info
            :title="$t('correlationToolDischarge.sscFromEo')"
            :description="$t('infobox.sscFromEoDescription')"
          />
        </v-row>
        <v-row class="justify-center mt-2 mb-1 mr-2">
          <v-btn
            class="rounded-button-left"
            style="width: 300px"
            @click="toggleSscChoice('existing')"
            :color="existingSscData ? 'primary' : 'light-grey'"
          >
            {{ $t("correlationToolDischarge.useExistingData") }}
          </v-btn>
          <v-btn
            class="rounded-button-right"
            style="width: 300px"
            @click="toggleSscChoice('formula')"
            :color="formulaSscData ? 'primary' : 'light-grey'"
          >
            {{ $t("correlationToolDischarge.applyFormulaToTurbidity") }}
          </v-btn>
        </v-row>
        <div
          v-if="existingSscData"
          class="mt-5"
          style="height: 179px !important;"
        >
          <v-select
            style="width: 70%"
            :label="$t('correlationTool.vectorLayer')"
            :items="vectorLayer"
            v-model="selectedSscLayer"
            return-object
            item-value="id"
            item-text="name"
            class="pt-2"
            @change="fetchSscGeometryData"
            dense
          />
          <v-select
            style="width: 70%"
            :label="$t('correlationTool.vectorLayerParam')"
            :items="parameterSscItems"
            v-model="selectedSscParameter"
            item-value="id"
            item-text="name"
            dense
          />
          <v-autocomplete
            style="width: 70%"
            v-model="selectedSscInSituStation"
            :items="inSituSscItems"
            :loading="inSituItemsLoading"
            item-text="name"
            return-object
            :label="$t('correlationTool.inSituStation')"
            dense
            class="pb-2"
          />
        </div>

        <div
          v-if="formulaSscData"
          class="mt-5"
          style="height: 190px !important;"
        >
          <v-row class="pt-2 pl-2" style="width: 90%">
            <v-select
              style="width: 85%"
              :label="$t('correlationTool.rasterLayer')"
              :items="rasterLayer"
              v-model="selectedRasterLayerId"
              item-value="layerId"
              item-text="title"
              dense
              class="pt-2"
            ></v-select>
          </v-row>
          <v-row class="pt-2 pl-2" style="width: 90%">
            <v-autocomplete
              style="width: 85%"
              v-model="selectedVirtualStation"
              :items="virtualStationsByRegion"
              item-text="name"
              :disabled="disabledVsSelection"
              return-object
              :label="vsLabel"
              dense
            />
          </v-row>
          <v-row class="pt-2" align="center" style="width: 90%">
            <v-col class="mt-6">
              <v-autocomplete
                :items="correlationFormulaItems"
                item-value="id"
                item-text="name"
                v-model="selectedCorrelationFormula"
                dense
                :label="$t('correlationTool.selectFormula')"
                :loading="loadCorrelationFormulaItems"
              >
                <template slot="item" slot-scope="data">
                  <span class="pr-4">{{ data.item.name }}</span>
                  <span
                    v-if="data.item.fitting_algorithm === 'exponential'"
                    v-html="exponentialFormulaLabel(data.item.coefficients)"
                    style="font-size: 12px"
                  />
                  <span
                    v-else
                    v-html="linearFormulaLabel(data.item.coefficients)"
                    style="font-size: 12px"
                  />
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="1" class="ml-0 pl-0">
              <v-row wrap no-gutters>
                <v-col cols="12" class="text-center">
                  <v-divider vertical />
                </v-col>
                <v-col cols="12" class="text-center"> {{ $t("or") }}</v-col>
                <v-col cols="12" class="text-center ">
                  <v-divider vertical />
                </v-col>
              </v-row>
            </v-col>
            <v-col class="pl-0 ">
              <v-btn
                color="primary"
                text
                outlined
                @click="showSaveDialog = true"
                class="mb-2"
              >
                {{ $t("sedimentTool.defineNewFormula") }}
              </v-btn>
              <correlation-input-data></correlation-input-data>
            </v-col>
          </v-row>
          <save-correlation
            v-model="showSaveDialog"
            :input-coefficients="[1, 1]"
            :endpoint-url="endpointUrl"
          />
        </div>
        <v-alert color="red" text type="error" v-if="error">
          {{ $t("correlationTool.errorMessage") }}
        </v-alert>
      </v-card-text>

      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer />
        <v-btn class="mr-5" text color="primary" @click="dialog = false">
          {{ $t("close") }}
        </v-btn>
        <v-btn
          :disabled="!allSelectedExisting && !allSelectedFormula"
          color="primary"
          @click="triggerCalculationCorrelation"
        >
          {{ $t("continue") }}
          <v-icon class="pl-3" v-if="loadResults">mdi-spin mdi-loading</v-icon>
        </v-btn>
        <correlation-create-formula
          :dialog="createFormel"
          :option="'dischargeCorrelation'"
          :result="result"
          :selected-in-situ-station="selectedInSituStation"
          :selected-raster-layer-id="
            formulaSscData ? selectedRasterLayerId : selectedSscParameter
          "
          :selected-virtual-station="
            formulaSscData ? selectedVirtualStation : selectedSscInSituStation
          "
          :selected-vector-parameter="selectedVectorParameter"
          :card-title="cardTitleFormula"
          :loadResults="loadResults"
          @closeCorrelation="
            dialog = false;
            createFormel = false;
          "
          @closeFormula="createFormel = false"
          @changeSelectedDate="setSelectedDate"
          @changeMaxDif="setMaxTimeDifValue"
          @changeSelectedFunction="setSelectedFunction"
        ></correlation-create-formula>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import TitleDescriptionInfo from "@/core/components/infos/TitleDescriptionInfo.vue";
import analyseMixin from "@/core/mixins/analyse.mixin";
import { mapActions, mapGetters, mapState } from "vuex";
import SaveCorrelation from "@/core/components/menu/eotool/analyse/SaveCorrelation.vue";
import axios from "axios";
import Url from "@/core/services/url.services";
import CorrelationCreateFormula from "@/core/components/menu/eotool/analyse/CorrelationCreateFormula.vue";
import CorrelationInputData from "@/core/components/menu/eotool/analyse/CorrelationInputData.vue";

export default {
  name: "CorrelationDischargeInputData",
  components: {
    CorrelationInputData,
    CorrelationCreateFormula,
    SaveCorrelation,
    TitleDescriptionInfo
  },
  mixins: [analyseMixin],
  data() {
    return {
      showSaveDialog: false,
      createFormel: false,
      selectedCorrelationFormula: null,
      dialog: false,
      error: false,
      metaData: {},
      selectedInSituStation: null,
      selectedSscInSituStation: null,
      inSituItems: [],
      inSituSscItems: [],
      inSituItemsLoading: false,
      existingSscData: true,
      formulaSscData: false,
      selectedRasterLayerId: null,
      selectedVirtualStation: null,
      selectedFunction: "exponential",
      selectedDate: [],
      endpointUrl: Url.correlation,
      loadResults: false,
      maxTimeDif: 24,
      maxTimeDifValue: 24,
      result: {
        coefficients: [1, 1],
        data_entries: 0,
        x: [],
        y: [],
        fitting_algorithm: ""
      }
    };
  },
  computed: {
    ...mapGetters("draw", ["virtualStationsByRegion"]),
    ...mapState("vector", ["activeVectorLayer", "vectorLayer"]),
    ...mapState("raster", ["rasterLayer"]),
    ...mapState("analyse", [
      "correlationFormulaItems",
      "loadCorrelationFormulaItems"
    ]),
    cardTitle() {
      return `
      ${this.$t(
        "correlationToolDischarge.correlationDischarge"
      )} -> SSC, 1 ${this.$t("of")} 2: ${this.$t(
        "correlationToolDischarge.selectInputData"
      )}
`;
    },
    cardTitleFormula() {
      return `
      ${this.$t(
        "correlationToolDischarge.correlationDischarge"
      )} -> SSC, 2 ${this.$t("of")} 2: ${this.$t(
        "correlationToolDischarge.createFormula"
      )}
`;
    },
    dataWatcherFormula() {
      return [
        this.selectedCorrelationFormula,
        this.selectedVirtualStation,
        this.selectedInSituStation,
        this.maxTimeDifValue,
        this.selectedRasterLayerId,
        this.selectedVectorLayer,
        this.selectedVectorParameter,
        this.selectedFunction,
        this.selectedDate
      ];
    },
    dataWatcherExisting() {
      return [
        this.maxTimeDifValue,
        this.selectedInSituStation,
        this.selectedSscInSituStation,
        this.selectedVectorLayer,
        this.selectedSscLayer,
        this.selectedVectorParameter,
        this.selectedSscParameter,
        this.selectedFunction,
        this.selectedDate
      ];
    },
    allSelectedExisting() {
      return this.dataWatcherExisting.every(v => !!v);
    },
    allSelectedFormula() {
      return this.dataWatcherFormula.every(v => !!v);
    }
  },
  methods: {
    ...mapActions("analyse", ["fetchCorrelationFormulas"]),
    toggleSscChoice(option) {
      if (option == "existing") {
        this.existingSscData = true;
        this.formulaSscData = false;
      } else {
        this.existingSscData = false;
        this.formulaSscData = true;
      }
    },
    triggerCalculationCorrelation() {
      if (this.allSelectedExisting && this.existingSscData) {
        this.calculateCorrelationExisting();
      } else if (this.allSelectedFormula && this.formulaSscData) {
        this.calculateCorrelationFormula();
      }
    },
    async calculateCorrelationFormula() {
      this.loadResults = true;
      const virtual_station_geom = this.$store.draw.get(
        this.selectedVirtualStation.id
      );
      const data = {
        correlation_formula: this.selectedCorrelationFormula,
        raster_layer: this.selectedRasterLayerId,
        virtual_station_geom: virtual_station_geom.geometry,
        layer_geometry: this.selectedInSituStation.id,
        insitu_property: this.selectedVectorParameter,
        max_time_dif: this.maxTimeDif,
        fitting_algorithm: this.selectedFunction,
        datetime_selection: this.datetimeLimit,
        start_date: this.selectedDate[0],
        end_date: this.selectedDate[1]
      };
      const response = await axios.post(
        Url.calculateCorrelationEoDischarge,
        data
      );
      if (response.status === 204) {
        this.error = true;
      } else if (response.status === 200) {
        this.error = false;
        this.result = response.data;
        this.createFormel = true;
      }
      this.loadResults = false;
    },
    async calculateCorrelationExisting() {
      this.loadResults = true;
      const data = {
        insitu_layer_geometry: this.selectedInSituStation.id,
        insitu_property: this.selectedVectorParameter,
        ssc_layer_geometry: this.selectedSscInSituStation.id,
        ssc_property: this.selectedSscParameter,
        fitting_algorithm: this.selectedFunction,
        max_time_dif: this.maxTimeDif,
        datetime_selection: this.datetimeLimit,
        start_date: this.selectedDate[0],
        end_date: this.selectedDate[1]
      };
      const response = await axios.post(
        Url.calculateCorrelationSscDischarge,
        data
      );
      if (response.status === 204) {
        this.error = true;
      } else if (response.status === 200) {
        this.error = false;
        this.result = response.data;
        this.createFormel = true;
      }
      this.loadResults = false;
    },
    setMaxTimeDifValue(val) {
      this.maxTimeDifValue = val;
      this.maxTimeDif = val;
    },
    setSelectedFunction(val) {
      this.selectedFunction = val;
    },
    setSelectedDate(val) {
      this.selectedDate = val;
    }
  },
  watch: {
    dataWatcherExisting() {
      if (
        this.createFormel &&
        this.allSelectedExisting &&
        this.existingSscData
      ) {
        this.calculateCorrelationExisting();
      }
    },
    dataWatcherFormula() {
      if (this.createFormel && this.allSelectedFormula && this.formulaSscData) {
        this.calculateCorrelationFormula();
      }
    }
  },
  async created() {
    await this.fetchCorrelationFormulas();
    const turLayer = await this.rasterLayer.filter(layer =>
      layer["title"].toLowerCase().includes("turb")
    )[0];
    if (turLayer) {
      this.selectedRasterLayerId = turLayer.layerId;
    }
  }
};
</script>

<style>
.rounded-button-right {
  border-top-left-radius: 0px;
  border-top-right-radius: 20px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 20px;
}

.rounded-button-left {
  border-top-left-radius: 20px;
  border-top-right-radius: 0px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 0px;
}
</style>
