<template>
  <v-dialog v-model="dialog" width="auto" max-width="700">
    <v-card>
      <v-card-title class="font-weight-regular">{{ cardTitle }}</v-card-title>
      <v-card-text class="pl-10 mt-4">
        <v-select
          style="width: 60%"
          :items="functionItems"
          item-text="val"
          item-value="key"
          v-model="selectedFunction"
          dense
          :label="$t('correlationTool.fitAlgorithm')"
          class="pt-2"
        />
        <v-subheader class="pa-0">
          {{ $t("correlationTool.maxTimeDif") }}: {{ daysLabel(maxTimeDif) }}
          {{ $t("correlationTool.days") }} {{ hoursLabel(maxTimeDif) }}
          {{ $t("correlationTool.hours") }}
        </v-subheader>
        <v-slider
          style="width: 60%"
          v-model="maxTimeDif"
          @end="setMaxTimeDifValue"
          min="1"
          max="168"
        />
        <v-checkbox
          v-model="datetimeLimit"
          :label="$t('correlationTool.limitDatetime')"
          class="mt-0 pt-0"
          :hide-details="datetimeLimit"
        ></v-checkbox>
        <v-menu
          v-if="datetimeLimit"
          ref="datetimeMenu"
          v-model="datetimeMenu"
          :close-on-content-click="false"
          :return-value.sync="selectedDate"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :value="dateRangeText"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker v-model="preSelectedDate" no-title scrollable range>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="datetimeMenu = false">
              {{ $t("cancel") }}
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="$refs.datetimeMenu.save(preSelectedDate.sort())"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
        <div
          v-if="showResult"
          class="pa-4"
          style="background-color: rgba(64,173,214,0.5)"
        >
          <v-row>
            <v-col>
              <span style="font-size: 20px"
                >Correlation ({{ result.data_entries }}
                {{ $t("correlationTool.dataPoints") }}):</span
              ><br />
              <b
                style="font-size: 20px"
                v-if="selectedFunction === 'exponential'"
                v-html="exponentialFormulaLabel(result.coefficients)"
              />
              <b
                style="font-size: 20px"
                v-else-if="selectedFunction === 'power'"
                v-html="powerFormulaLabel(result.coefficients)"
              />
              <b
                v-else
                style="font-size: 20px"
                v-html="linearFormulaLabel(result.coefficients)"
              />
              <br />
            </v-col>
            <v-col class="mt-1 ml-12">
              <v-icon x-large class="pl-3" v-if="loadResults"
                >mdi-spin mdi-loading</v-icon
              >
            </v-col>
          </v-row>
        </div>
        <v-alert color="red" text type="error" v-if="error">
          {{ $t("correlationTool.errorMessage") }}
        </v-alert>
        <!--        <v-progress-linear-->
        <!--          :active="loadResults"-->
        <!--          indeterminate-->
        <!--          absolute-->
        <!--          top-->
        <!--          color="primary accent-4"-->
        <!--        ></v-progress-linear>-->
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          class="mr-5"
          text
          color="primary"
          @click="$emit('closeFormula', true)"
        >
          {{ $t("close") }}
        </v-btn>

        <save-correlation
          v-if="saveDialog"
          v-model="saveDialog"
          @formulaSaved="formulaSaved()"
          :input-coefficients="result.coefficients"
          :fitting-algorithm="result.fitting_algorithm"
          :endpoint-url="
            option == 'correlation' ? endpointUrl : endpointDischargeUrl
          "
        />
        <correlation-plot
          :disabled="error"
          :x="result.x"
          :y="result.y"
          :coefficients="result.coefficients"
          :fittingAlgorithm="result.fitting_algorithm"
          @saveCorrelation="saveDialog = true"
          @exportData="exportData"
          :exportLoading="loadDataExport"
          label-x="correlationToolDischarge.xAxis"
          label-y="correlationToolDischarge.yAxis"
          plot-type="log"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import CorrelationPlot from "@/core/components/plot/CorrelationPlot.vue";
import SaveCorrelation from "@/core/components/menu/eotool/analyse/SaveCorrelation.vue";
import Url from "@/core/services/url.services";
import axios from "axios";
import analyseMixin from "@/core/mixins/analyse.mixin";
import { mapActions } from "vuex";

export default {
  name: "CorrelationCreateFormula",
  mixins: [analyseMixin],
  components: { SaveCorrelation, CorrelationPlot },
  props: {
    result: Object,
    loadResults: Boolean,
    selectedVirtualStation: Object,
    selectedInSituStation: Object,
    selectedRasterLayerId: String,
    selectedVectorParameter: String,
    cardTitle: String,
    option: String,
    dialog: Boolean
  },
  data() {
    return {
      saveDialog: false,
      showSaveDialog: false,
      selectedCorrelationFormula: null,
      datetimeLimit: false,
      selectedFunction: "exponential",
      maxTimeDif: 24,
      maxTimeDifValue: 24,
      error: false,
      datetimeMenu: false,
      endpointUrl: Url.correlation,
      endpointDischargeUrl: Url.correlationDischarge,
      preSelectedDate: [],
      selectedDate: [],
      loadDataExport: false
    };
  },
  computed: {
    showSaveButton() {
      return !this.error && this.$keycloak.authenticated;
    },
    showResult() {
      return this.result.data_entries !== 0 && !this.error;
    },
    dateRangeText() {
      return this.selectedDate.join(" / ");
    },
    functionItems() {
      return [
        { key: "exponential", val: this.$t("correlationTool.exponential") },
        { key: "power", val: this.$t("correlationTool.power") },
        { key: "linear", val: this.$t("correlationTool.linear") },
        {
          key: "polynomial_2nd",
          val: this.$t("correlationTool.polynomial_2nd")
        },
        {
          key: "polynomial_3rd",
          val: this.$t("correlationTool.polynomial_3rd")
        }
      ];
    }
  },
  methods: {
    ...mapActions("app", ["showSnackbar"]),
    formulaSaved() {
      this.dialog = false;
      this.$emit("closeCorrelation", true);
    },
    showDialog(value) {
      this.saveDialog = value;
    },
    setMaxTimeDifValue(val) {
      this.maxTimeDifValue = val;
    },
    async exportData() {
      this.loadDataExport = true;
      try {
        const virtual_station_geom = this.$store.draw.get(
          this.selectedVirtualStation.id
        );
        const data = {
          virtual_station_geom: virtual_station_geom.geometry,
          raster_layer: this.selectedRasterLayerId,
          layer_geometry: this.selectedInSituStation.id,
          insitu_property: this.selectedVectorParameter,
          max_time_dif: this.maxTimeDif,
          fitting_algorithm: this.selectedFunction,
          datetime_selection: this.datetimeLimit,
          start_date: this.selectedDate[0],
          end_date: this.selectedDate[1],
          x_axis_name: "Discharge",
          y_axis_name: "SSC"
        };
        const response = await axios.post(Url.csvCorrelation, data, {
          responseType: "blob"
        });
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "correlation-data.csv"); //or any other extension
        document.body.appendChild(link);
        link.click();
      } catch {
        this.showSnackbar({
          show: true,
          message: "Export data is not yet possible using existing SSC data.",
          color: "error"
        });
      }
      this.loadDataExport = false;
    }
  },
  watch: {
    selectedDate() {
      if (this.datetimeLimit && this.selectedDate.length === 2) {
        this.$emit("changeSelectedDate", this.selectedDate);
      }
    },
    maxTimeDifValue: function() {
      this.$emit("changeMaxDif", this.maxTimeDifValue);
    },
    selectedFunction: function() {
      this.$emit("changeSelectedFunction", this.selectedFunction);
    },
    datetimeLimit() {
      if (this.datetimeLimit && this.selectedDate.length === 2) {
        this.$emit("changeSelectedDate", this.selectedDate);
      }
    }
  }
};
</script>

<style></style>
