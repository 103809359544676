<template>
  <div
    id="transect-window"
    class="flex-grow-0"
    style="align-self: end; height: 40vh"
    v-if="showPlotFeature"
  >
    <dialog class="d-flex fill-height" style="width: 100%; border: 0 ">
      <div class="pa-2">
        <v-list>
          <v-list-item v-if="!animationMode">
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  fab
                  v-bind="attrs"
                  v-on="on"
                  color="primary"
                  small
                  @click="fetchTransect(profileLayer.datetime)"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>{{ $t("addTimestepToPlot") }}</span>
            </v-tooltip>
          </v-list-item>
          <v-list-item v-if="!animationMode">
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  fab
                  v-bind="attrs"
                  v-on="on"
                  color="primary"
                  small
                  @click="toggleAnimation"
                >
                  <v-icon>mdi-play</v-icon>
                </v-btn>
              </template>
              <span>{{ $t("showAnimation") }}</span>
            </v-tooltip>
          </v-list-item>
          <v-list-item v-if="animationMode">
            <v-tooltip>
              <template v-slot:activator="{ props }">
                <v-btn
                  fab
                  v-bind="props"
                  color="primary"
                  small
                  @click="toggleAnimation"
                >
                  <v-icon>mdi-chevron-left</v-icon>
                </v-btn>
              </template>
            </v-tooltip>
          </v-list-item>
        </v-list>
      </div>
      <div class="flex">
        <v-list>
          <v-list-item v-if="profile">
            <span> {{ profile.name }}</span>
          </v-list-item>
          <v-list-item>
            <plotly
              v-if="!animationMode"
              :data="data"
              :layout="layout"
              :responsive="true"
              style="height: 20vh"
            ></plotly>
            <div v-else id="myDiv"></div>
          </v-list-item>
        </v-list>
      </div>
      <div style="position: absolute; right: 0">
        <v-btn color="primary" text @click="reset()">
          {{ $t("reset") }}
        </v-btn>
        <v-btn icon variant="plain" @click="setShowPlotFeature(false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
    </dialog>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import virtualStationMixin from "@/core/mixins/virtualStation.mixin";
import { Plotly } from "vue-plotly";
import plotly from "plotly.js-dist";

export default {
  name: "BottomProfilePlot",
  components: { Plotly },
  mixins: [virtualStationMixin],
  data: () => ({
    data: [],
    animationMode: false
  }),
  computed: {
    ...mapState("coastsPlot", ["profile", "profileLayer", "profileTimesteps"]),
    ...mapState("plot", ["lineStation", "showPlotFeature"]),
    ...mapState("management", ["accessToken"]),
    plotEntries() {
      return this.data.map(x => x.id);
    },
    layout() {
      return {
        showLegend: true,
        height: 230,
        width: 1300,
        xaxis: {
          title: {
            text: this.$t("transect.meter")
          }
        },
        yaxis: {
          title: {
            text: this.profileLayer.unit
          }
        },
        margin: {
          l: 40,
          r: 50,
          b: 90,
          t: 5,
          pad: 6
        }
      };
    },
    config() {
      return {
        responsive: true,
        modeBarButtonsToRemove: [
          "toggleHover",
          "sendDataToCloud",
          "toggleSpikelines",
          "resetScale2d",
          "pan2d",
          "zoom2d",
          "select2d",
          "lasso2d"
        ]
      };
    }
  },
  methods: {
    ...mapActions("plot", [
      "resetDataCache",
      "setShowTransectPlot",
      "resetDataCache",
      "setShowPlotFeature"
    ]),
    ...mapActions("coastsPlot", ["setShowProfilePlot", "setShowStationPlot"]),
    toggleAnimation() {
      this.animationMode = !this.animationMode;
      if (this.animationMode) {
        setTimeout(() => {
          this.formPlay();
        }, 100);
      } else {
        this.data = [];
        this.fetchTransect(this.profileLayer.datetime, true);
      }
    },
    async formPlay() {
      const reverseProfileTimesteps = [...this.profileTimesteps].reverse();
      this.data = [];
      for (let i = 0; i <= reverseProfileTimesteps.length - 1; i++) {
        await this.fetchTransect(reverseProfileTimesteps[i].datetime, false);
      }

      setTimeout(() => {
        this.getMaxValues();
        this.trace = [this.data[0]];
        this.frames = [];
        let steps = [];
        this.data.forEach(trace => {
          trace.text = trace.name;
          this.frames.push({
            name: trace.name,
            data: [trace]
          });
          steps.push({
            label: trace.name,
            method: "animate",
            args: [
              [trace.name],
              {
                mode: "immediate",
                frame: { redraw: false, duration: 500 },
                transition: { duration: 100 }
              }
            ]
          });
        });
        // Create the plot:
        //
        plotly.newPlot("myDiv", {
          data: [this.data[0]],
          layout: {
            showLegend: true,
            height: 230,
            width: 1300,
            xaxis: {
              title: {
                text: this.$t("transect.meter")
              }
            },
            margin: {
              l: 40,
              r: 50,
              b: 10,
              t: 5,
              pad: 6
            },
            yaxis: {
              range: [this.minValue, this.maxValue],
              title: {
                text: this.profileLayer.unit
              }
            },
            sliders: [
              {
                pad: { l: 50, t: 25 },
                x: 0.05,
                len: 0.95,
                currentvalue: {
                  visible: true,
                  xanchor: "right",
                  prefix: "Timestep: ",
                  font: {
                    color: "#888",
                    size: 20
                  }
                },
                transition: { duration: 200 },
                steps: steps
              }
            ],
            updatemenus: [
              {
                type: "buttons",
                showactive: true,
                direction: "left",
                x: 0.05,
                y: 0,
                xanchor: "right",
                yanchor: "top",
                pad: { t: 80, r: 10 },
                bordercolor: "#1D4180",
                buttons: [
                  {
                    label: "Play",
                    method: "animate",
                    args: [
                      null,
                      {
                        fromcurrent: true,
                        frame: { redraw: false, duration: 1000 },
                        transition: { duration: 10 }
                      }
                    ]
                  },
                  {
                    method: "animate",
                    args: [
                      [null],
                      {
                        mode: "immediate",
                        transition: { duration: 0 },
                        frame: { duration: 0, redraw: false }
                      }
                    ],
                    label: "Pause"
                  }
                ]
              }
            ]
          },
          frames: this.frames
        });
      }, 500);
    },
    reset() {
      this.data = [];
      this.setShowProfilePlot(false);
      this.setShowPlotFeature(false);
    },

    async fetchTransect(datetime, showLegend) {
      const response = await this.$rastless.post(
        `/layers/${this.profileLayer.layerId}/${datetime}/transect?token=${this.accessToken}`,
        this.profile.geometry
      );
      const data = response.data;
      for (const [key, values] of Object.entries(data.data)) {
        const dataEntry = {
          id: datetime,
          name: `${datetime.replace("T", " ")} ${key}`,
          x: data.cumulated_length,
          y: values,
          coords: data.coordinates,
          showlegend: showLegend
        };
        this.data.push(dataEntry);
      }
    },
    getMaxValues() {
      let max = [];
      let min = [];
      this.data.forEach(trace => {
        const filtered = trace.y.filter(point => point != null);
        if (filtered.length != 0) {
          min.push(Math.min(...filtered));
          max.push(Math.max(...filtered));
        }
      });
      this.maxValue = Math.max(...max);
      this.minValue = Math.min(...min);
      const difference = this.maxValue - this.minValue;
      this.maxValue = this.maxValue + difference / 10;
      this.minValue = this.minValue - difference / 10;
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.setShowStationPlot(false);
      this.setShowTransectPlot(false);
      this.resetDataCache();
      this.fetchTransect(this.profileLayer.datetime, true);
      this.setShowPlotFeature(true);
    });
  },
  watch: {
    "profileLayer.layerId"() {
      this.data = [];
      this.fetchTransect(this.profileLayer.datetime, true);
    }
  }
};
</script>

<style scoped>
#bottom-plot {
  width: 90%;
}
</style>
