<template>
  <v-menu v-model="openMenu" left offset-x nudge-left="10">
    <template v-slot:activator="{ on: onMenu }">
      <v-tooltip :bottom="!!iconStyles" :left="!iconStyles">
        <template #activator="{ on: onTooltip }">
          <v-btn
            id="basemap-switch"
            tile
            absolute
            top
            :elevation="iconStyles ? '0' : '2'"
            :style="{
              'background-color': getButtonBackgroundColor(),
              'margin-top':
                iconStyles && iconStyles.marginTop
                  ? iconStyles.marginTop
                  : '140px'
            }"
            @click="closeOtherMenu"
            right
            fab
            small
            class="btn-fix"
            v-on="{ ...onMenu, ...onTooltip }"
          >
            <v-icon
              v-if="!openMenu"
              :style="{
                'background-color':
                  iconStyles && iconStyles.backgroundColor
                    ? iconStyles.backgroundColor
                    : 'initial',
                color:
                  iconStyles && iconStyles.iconColor
                    ? iconStyles.iconColor
                    : 'grey darken-3'
              }"
              >mdi-map</v-icon
            >
            <v-icon
              v-else
              :color="
                iconStyles && iconStyles.iconColor
                  ? iconStyles.iconColor
                  : 'white'
              "
              >mdi-map</v-icon
            >
          </v-btn>
        </template>

        <span>{{ $t("basemapLayer") }}</span>
      </v-tooltip>
    </template>
    <v-list
      :style="{
        'margin-top': iconStyles && iconStyles.marginTop ? '44px' : ''
      }"
    >
      <span class="pl-2 font-weight-bold">{{ $t("basemapLayer") }}</span>
      <v-divider></v-divider>
      <v-list-item
        v-for="item in layers"
        :key="item.id"
        @click="switchBasemap(item.id)"
      >
        <v-list-item-title>{{ $t(item.id) }}</v-list-item-title>
      </v-list-item>
      <v-list-item>
        <v-list-item-action>
          <v-checkbox @change="toggleTerrain">
            <template v-slot:label>
              <div class="pl-2">Terrain</div>
            </template>
          </v-checkbox>
        </v-list-item-action>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: "BasemapSwitch",
  props: ["map", "layers", "iconStyles"],
  data: function() {
    return {
      openMenu: false
    };
  },
  methods: {
    switchBasemap(layerId) {
      this.map.setLayoutProperty(layerId, "visibility", "visible");
      const otherBasemaps = this.layers.filter(layer => layer.id !== layerId);
      otherBasemaps.forEach(layer => {
        this.map.setLayoutProperty(layer.id, "visibility", "none");
      });
    },
    toggleTerrain(setTerrain) {
      if (setTerrain) {
        this.map.setTerrain({ source: "terrainSource", exaggeration: 2 });
      } else {
        this.map.setTerrain();
      }
    },
    closeOtherMenu() {
      this.$emit("toggleAnalysisStation", false);
    },
    getButtonBackgroundColor() {
      if (this.openMenu) return "grey";
      if (
        !this.openMenu &&
        this.iconStyles &&
        this.iconStyles.backgroundColor
      ) {
        return this.iconStyles.backgroundColor;
      }
      return "white";
    }
  }
};
</script>

<style scoped>
#basemap-switch {
  margin-top: 140px;
}

.btn-fix:focus::before {
  opacity: 0 !important;
}

.btn-fix:hover::before {
  opacity: 0.08 !important;
}

.mapboxgl-ctrl-logo {
  display: none !important;
}
</style>
