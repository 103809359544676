<template>
  <div
    id="transect-window"
    class="flex-grow-0"
    style="align-self: end"
    :style="dialogStyle"
    v-if="showPlotFeature"
  >
    <dialog class="d-flex fill-height" style="width: 100%; border: 0 ">
      <div class="flex">
        <v-list>
          <v-list-item>
            <span>
              {{ parameter.full_name }} [{{ this.dataCache[0].unit }}]</span
            >
          </v-list-item>
          <v-list-item>
            <div
              v-if="!loading"
              ref="plot"
              id="bottom-plot"
              style="height: 20vh"
            ></div>
            <v-overlay v-else opacity="0.2" absolute style="height: 20vh">
              <div class="text-center">
                <v-progress-circular
                  indeterminate
                  color="primary"
                  size="64"
                ></v-progress-circular>
              </div>
            </v-overlay>
          </v-list-item>
          <!--          <v-list-item class="py-0 pr-16 mr-7 justify-end">-->
          <!--            <div>-->
          <!--              <v-btn color="primary" @click="exportData"-->
          <!--              >{{ $t("exportPlotData") }}-->
          <!--              </v-btn>-->
          <!--            </div>-->
          <!--          </v-list-item>-->
        </v-list>
      </div>
      <div style="position: absolute; right: 0">
        <v-btn color="primary" text @click="reset()">
          {{ $t("reset") }}
        </v-btn>
        <v-btn icon variant="plain" @click="setShowPlotFeature(false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
    </dialog>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import virtualStationMixin from "@/core/mixins/virtualStation.mixin";
import Plotly from "plotly.js-dist";
import axios from "axios";
import PlotUtils from "@/core/utils/plot.utils";

export default {
  name: "CoastsStationsBottomPlot",
  mixins: [virtualStationMixin],
  data: () => ({
    fullscreen: false,
    dataCache: [],
    animationMode: false,
    selectedDatetimes: [],
    createReportLoading: false,
    exportDataLoading: false,
    loading: false,
    layout: {
      showlegend: true,
      loaded: false,
      margin: {
        l: 40,
        r: 50,
        b: 20,
        t: 20,
        pad: 6
      }
    },
    config: {
      responsive: true,
      modeBarButtonsToRemove: [
        "toggleHover",
        "sendDataToCloud",
        "toggleSpikelines",
        "resetScale2d",
        "pan2d",
        "zoom2d",
        "select2d",
        "lasso2d"
      ]
    }
  }),
  computed: {
    ...mapState("coastsPlot", ["site", "parameter"]),
    ...mapState("plot", ["showPlotFeature"]),
    plotEntries() {
      return this.data.map(x => x.id);
    },
    dialogStyle() {
      return { width: "100%", height: "40vh" };
    }
  },
  methods: {
    ...mapActions("plot", [
      "resetDataCache",
      "setLineStation",
      "setShowTransectPlot",
      "setShowPlotFeature"
    ]),
    ...mapActions("coastsPlot", [
      "setShowStationPlot",
      "setShowProfilePlot",
      "setParameter"
    ]),
    reset() {
      this.data = [];
      this.setShowStationPlot(false);
      this.setShowPlotFeature(false);
      this.setParameter(null);
    },
    createPlot() {
      this.layout.yaxis = {
        title: {
          text: this.dataCache[0].unit,
          standoff: 20
        }
      };
      Plotly.newPlot("bottom-plot", this.dataCache, this.layout, this.config);
    },

    async fetchStationData() {
      this.loading = true;
      let data = [];
      this.dataCache = [];
      for (const param of this.parameter.data) {
        const response = await axios.get(
          `/external/qld/${this.parameter.type}/sites/${this.site.site_id}/?param=${param.name}`
        );
        data.push(response.data);
      }
      data.forEach((dataSet, idx) => {
        let copyData = [...dataSet.data[0]];
        copyData.forEach((datapoints, index) => {
          if (datapoints <= -0.5) {
            dataSet.data[0][index] = null;
          }
        });
        const plotData = PlotUtils.createPlotEntry({
          layerId: this.site.site_id,
          layerType: "vector",
          x: dataSet.datetimes,
          y: dataSet.data[0],
          legendName: `${this.parameter.data[idx].full_name}-Station ${this.site.site_name}`,
          unit: this.parameter.data[0].unit,
          ident: `${this.parameter.data[idx].name}`
        });
        this.dataCache.push(plotData);
      });
      this.loading = false;
    }
  },
  mounted() {
    this.setShowProfilePlot(false);
    this.setShowTransectPlot(false);
    this.setLineStation(false);
    this.resetDataCache();
    this.setShowPlotFeature(true);
    this.fetchStationData().then(() => {
      this.createPlot();
    });
  },
  watch: {
    site() {
      this.data = [];
      if (!this.loading) {
        this.fetchStationData().then(() => {
          this.createPlot();
        });
      }
    },
    parameter() {
      this.data = [];
      if (!this.loading) {
        this.fetchStationData().then(() => {
          this.createPlot();
        });
      }
    },
    showPlotFeature() {
      if (this.showPlotFeature) {
        this.data = [];
        if (!this.loading) {
          this.fetchStationData().then(() => {
            this.createPlot();
          });
        }
      }
    }
  }
};
</script>

<style scoped>
#bottom-plot {
  width: 90%;
}
</style>
