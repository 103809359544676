<template>
  <v-dialog v-model="dialog" width="600">
    <template v-slot:activator="{ on }">
      <v-icon class="not-highlighted" size="20px" v-on="on"
        >mdi-information-outline</v-icon
      >
    </template>

    <v-card>
      <v-card-title class="mb-5">
        {{ title }}
      </v-card-title>
      <v-card-text>
        {{ description }}
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="closeDialog">
          {{ $t("close") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "TitleDescriptionInfo",
  data: function() {
    return {
      dialog: false
    };
  },
  props: {
    title: {
      type: String
    },
    description: {
      type: String
    }
  },
  methods: {
    closeDialog() {
      this.dialog = false;
    }
  }
};
</script>

<style scoped>
.not-highlighted:focus::after {
  opacity: 0 !important;
}
</style>
