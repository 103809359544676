<template>
  <v-dialog v-model="showDialog" persistent max-width="700">
    <v-card>
      <v-card-title>
        <span>{{ $t("correlationTool.saveFormula") }}</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-form ref="formulaForm" v-model="validForm" lazy-validation>
            <v-text-field
              label="Name*"
              required
              :rules="nameRules"
              v-model="formulaName"
            ></v-text-field>
            <v-select
              :items="functionItems"
              item-text="val"
              item-value="key"
              v-model="selectedFunction"
              dense
              :label="$t('correlationTool.fitAlgorithm')"
              class="pt-2"
            />
            <div
              v-if="selectedFunction === 'exponential'"
              v-html="exponentialFormulaLabel(coefficients)"
              class="mb-4"
              style="font-size: 18px; font-weight: bold"
            />
            <div
              v-else-if="selectedFunction === 'power'"
              v-html="powerFormulaLabel(coefficients)"
              class="mb-4"
              style="font-size: 18px; font-weight: bold"
            />
            <div
              v-else
              v-html="linearFormulaLabel(coefficients)"
              class="mb-4"
              style="font-size: 18px; font-weight: bold"
            />
            <v-row class="pt-4">
              <v-col
                v-for="(num, i) in coefficients"
                :key="i"
                cols="6"
                class="py-0"
              >
                <v-text-field
                  type="number"
                  v-model="coefficients[i]"
                  :label="firstEntry"
                  :rules="numRules"
                  v-if="i === 0"
                />
                <v-text-field
                  type="number"
                  v-model="coefficients[i]"
                  :rules="numRules"
                  :label="additionalEntry(i)"
                  v-else
                />
              </v-col>
            </v-row>
          </v-form>
          <small>*indicates required field</small>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="cancelForm">
          Close
        </v-btn>
        <v-btn color="primary" text @click="saveForm">
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";
import Url from "@/core/services/url.services";
import analyseMixin from "@/core/mixins/analyse.mixin";
import { mapActions } from "vuex";

export default {
  name: "SaveCorrelation",
  mixins: [analyseMixin],
  props: {
    showDialog: {
      type: Boolean
    },
    fittingAlgorithm: {
      type: String,
      default: "exponential"
    },
    inputCoefficients: {
      type: Array,
      default: () => [1, 1]
    },
    endpointUrl: {
      type: String,
      default: Url.correlation
    }
  },
  model: {
    prop: "showDialog",
    event: "showDialog"
  },
  data: function() {
    return {
      validForm: true,
      formulaName: "",
      coefficients: this.inputCoefficients,
      selectedFunction: this.fittingAlgorithm
    };
  },
  computed: {
    nameRules() {
      return [v => !!v || this.$t("correlationTool.nameRequired")];
    },
    numRules() {
      return [v => !!v || this.$t("correlationTool.numNotSet")];
    },
    functionItems() {
      return [
        { key: "exponential", val: this.$t("correlationTool.exponential") },
        { key: "power", val: this.$t("correlationTool.power") },
        { key: "linear", val: this.$t("correlationTool.linear") },
        {
          key: "polynomial_2nd",
          val: this.$t("correlationTool.polynomial_2nd")
        },
        {
          key: "polynomial_3rd",
          val: this.$t("correlationTool.polynomial_3rd")
        }
      ];
    },
    firstEntry() {
      return this.selectedFunction === "exponential"
        ? "A"
        : this.$t("correlationTool.intercept");
    }
  },
  methods: {
    ...mapActions("analyse", [
      "fetchCorrelationFormulas",
      "fetchCorrelationDischargeFormulas"
    ]),
    async saveForm() {
      await this.$refs.formulaForm.validate();
      if (this.validForm) {
        const data = {
          name: this.formulaName,
          fitting_algorithm: this.selectedFunction,
          coefficients: this.coefficients
        };
        axios
          .post(this.endpointUrl, data)
          .then(
            () => {
              this.$store.dispatch("app/showSnackbar", {
                show: true,
                message: this.$t("correlationTool.formulaSaved"),
                color: "success"
              });
              this.$emit("formulaSaved", true);
              this.$emit("showDialog", false);
            },
            () => {
              this.$store.dispatch("app/showSnackbar", {
                show: true,
                message: this.$t("errorMessage"),
                color: "error"
              });
            }
          )
          .then(() => {
            this.fetchCorrelationFormulas();
            this.fetchCorrelationDischargeFormulas();
          });
      }
    },
    cancelForm() {
      this.$emit("showDialog", false);
    },
    additionalEntry(i) {
      return this.selectedFunction === "exponential"
        ? "B"
        : this.$t("correlationTool.degree") + " " + i;
    }
  },
  watch: {
    selectedFunction(val) {
      if (val === "polynomial_2nd") {
        this.coefficients = [1.0, 1.0, 1.0];
      } else if (val === "polynomial_3rd") {
        this.coefficients = [1.0, 1.0, 1.0, 1.0];
      } else {
        this.coefficients = [1.0, 1.0];
      }
    }
  }
};
</script>

<style scoped></style>
