<template>
  <v-tooltip left>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        id="showPlot-button"
        tile
        absolute
        :style="{ 'background-color': showPlotFeature ? 'grey' : 'white' }"
        top
        @click="togglePlot"
        right
        fab
        small
        v-on="on"
        v-bind="attrs"
        class="showPlot-button"
      >
        <v-icon v-if="!showPlotFeature" color="grey darken-3"
          >mdi-chart-line</v-icon
        >
        <v-icon v-else color="white">mdi-chart-line</v-icon>
      </v-btn>
    </template>
    <span v-if="!showPlotFeature">{{ $t("ShowPlot") }}</span>
    <span v-else>{{ $t("HidePlot") }}</span>
  </v-tooltip>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "ShowPlot",
  computed: {
    ...mapState("plot", ["showPlotFeature"])
  },
  methods: {
    ...mapActions("plot", ["setShowPlotFeature"]),
    togglePlot() {
      this.setShowPlotFeature(!this.showPlotFeature);
      window.setTimeout(() => this.$store.visualizeMap.resize(), 100);
    }
  }
};
</script>

<style>
.showPlot-button {
  margin-top: 90px;
}
</style>
