<template>
  <v-menu
    v-model="openMenu"
    :close-on-content-click="false"
    left
    offset-x
    nudge-left="10"
  >
    <template v-slot:activator="{ on: onMenu }">
      <v-tooltip left>
        <template #activator="{ on: onTooltip }">
          <v-btn
            id="layer-switch"
            tile
            absolute
            top
            :style="{ 'background-color': openMenu ? 'grey' : 'white' }"
            right
            fab
            small
            class="btn-fix"
            @click="closeOtherMenu"
            v-on="{ ...onMenu, ...onTooltip }"
          >
            <v-icon v-if="!openMenu" color="grey darken-3">mdi-layers</v-icon>
            <v-icon v-else color="white">mdi-layers</v-icon>
          </v-btn>
        </template>

        <span>{{ $t("Layer") }}</span>
      </v-tooltip>
    </template>

    <v-list>
      <span class="pl-2 font-weight-bold">{{ $t("Layer") }}</span>
      <v-divider></v-divider>
      <v-list-item>
        <v-list-item-action>
          <v-checkbox @change="toggleBackground">
            <template v-slot:label>
              <div class="pl-2">RGB</div>
            </template>
          </v-checkbox>
        </v-list-item-action>
      </v-list-item>
      <!--      <v-list-item v-if="this.$appConfig.keycloakClient === 'coasts'">-->
      <!--        <v-list-item-action>-->
      <!--          <v-checkbox @change="toggleTerrain3d">-->
      <!--            <template v-slot:label>-->
      <!--              <div class="pl-2">terrain 3D</div>-->
      <!--            </template>-->
      <!--          </v-checkbox>-->
      <!--        </v-list-item-action>-->
      <!--      </v-list-item>-->
      <!--      <v-list-item v-if="showTerrain3d && this.$appConfig.keycloakClient === 'coasts'">-->
      <!--        <v-slider-->
      <!--          v-model="terrainElevation"-->
      <!--          @change="setTerrainElevation"-->
      <!--          vertical-->
      <!--          dense-->
      <!--          step="3"-->
      <!--          min="1"-->
      <!--          max="100"-->
      <!--        ></v-slider>-->
      <!--      </v-list-item>-->

      <v-list-item>
        <v-list-item-action>
          <v-checkbox @change="toggle3d">
            <template v-slot:label>
              <div class="pl-2">3D</div>
            </template>
          </v-checkbox>
        </v-list-item-action>
      </v-list-item>
      <v-list-item v-if="show3d">
        <v-slider
          v-model="elevation"
          @change="setElevation"
          vertical
          dense
          step="3"
          min="1"
          max="100"
        ></v-slider>
      </v-list-item>
      <v-list-item class="pl-8" v-if="show3d">
        {{ $t("elevation") }}
      </v-list-item>
    </v-list>

    <!--    <v-list>-->
    <!--      <v-list-item>-->
    <!--        <v-list-item-action>-->
    <!--          <v-btn-->
    <!--            tile-->
    <!--            absolute-->
    <!--            top-->
    <!--            right-->
    <!--            fab-->
    <!--            small-->
    <!--            @click="toggleBackground"-->
    <!--          >-->
    <!--            <span class="font-weight-bold">3D</span>-->
    <!--          </v-btn>-->
    <!--        </v-list-item-action>-->
    <!--      </v-list-item>-->
    <!--    </v-list>-->
  </v-menu>
</template>

<script>
import RasterLayerMixin from "@/core/mixins/rasterLayer.mixin";
import MapboxMixins from "@/core/mixins/mapbox.mixins";
import { mapActions, mapState } from "vuex";

export default {
  name: "LayerSwitch",
  props: ["map"],
  mixins: [RasterLayerMixin, MapboxMixins],
  data: function() {
    return {
      openMenu: false,
      activeBackgroundLayer: false,
      showRgb: false,
      show3d: false,
      showTerrain3d: false,
      elevation: 10
    };
  },
  computed: {
    ...mapState("raster", ["activeRasterLayer"]),
    ...mapState("management", ["accessToken"])
  },
  methods: {
    ...mapActions("app", ["showSnackbar"]),
    toggle3d() {
      this.show3d = !this.show3d;
      const map = this.$store.visualizeMap;
      if (map && this.activeRasterLayer) {
        if (this.show3d) {
          this.showTerrain(
            map,
            this.activeRasterLayer.layerId,
            this.activeRasterLayer.datetime,
            this.elevation
          );
        } else {
          this.hideTerrain(map);
        }
      }
    },
    toggleBackground() {
      this.activeBackgroundLayer = !this.activeBackgroundLayer;
      // const nameBackground = this.getMapboxLayerName(this.activeRasterLayer.backgroundId, "raster");
      // const nameLayer = this.getMapboxLayerName(this.activeRasterLayer.layerId, "raster");
      if (this.activeBackgroundLayer) {
        let zIndex = "z-index-1";
        if (
          (this.$appConfig.keycloakClient === "coasts" &&
            this.activeRasterLayer.product === "sdb") ||
          (this.$appConfig.keycloakClient === "coasts" &&
            this.activeRasterLayer.product === "dem")
        ) {
          zIndex = "z-index-2";
        }
        this.addRasterLayer(
          "background",
          this.activeRasterLayer.backgroundId,
          this.activeRasterLayer,
          zIndex,
          this.accessToken
        );
        // this.$store.visualizeMap.U.moveLayer("backgroundSource", nameLayer.layer);
      } else {
        this.removeRasterLayer("backgroundSource");
      }
    },
    setElevation() {
      const map = this.$store.visualizeMap;
      if (map) {
        this.showTerrain(
          map,
          this.activeRasterLayer.layerId,
          this.activeRasterLayer.datetime,
          this.elevation
        );
      }
    },
    closeOtherMenu() {
      this.$emit("toggleAnalysisStation", false);
    }
  },
  watch: {
    activeRasterLayer() {
      const map = this.$store.visualizeMap;
      this.removeRasterLayer("backgroundSource");
      if (this.activeBackgroundLayer) {
        let zIndex = "z-index-1";
        if (
          (this.$appConfig.keycloakClient === "coasts" &&
            this.activeRasterLayer.product === "sdb") ||
          (this.$appConfig.keycloakClient === "coasts" &&
            this.activeRasterLayer.product === "dem")
        ) {
          zIndex = "z-index-2";
        }
        this.addRasterLayer(
          "background",
          this.activeRasterLayer.backgroundId,
          this.activeRasterLayer,
          zIndex,
          this.accessToken
        );
      }
      if (this.show3d) {
        this.showTerrain(
          map,
          this.activeRasterLayer.layerId,
          this.activeRasterLayer.datetime,
          this.elevation
        );
      }
    }
  }
};
</script>

<style scoped>
#layer-switch {
  margin-top: 190px;
}

.btn-fix:focus::before {
  opacity: 0 !important;
}

.btn-fix:hover::before {
  opacity: 0.08 !important;
}

.mapboxgl-ctrl-logo {
  display: none !important;
}
</style>
