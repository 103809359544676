<template>
  <v-dialog v-model="dialog" max-width="1000" scrollable>
    <template v-slot:activator="{ on, attrs }">
      <v-icon
        v-bind="attrs"
        v-on="on"
        dense
        class="mt-6 mr-3"
        color="primary"
        :title="$t('metaData.detailedInformation')"
        >mdi-information
      </v-icon>
    </template>
    <v-card>
      <v-card-title style="color: black;">
        <span class="mt-5 ml-1 text-h5">
          {{ $t("metaData.metaDataCurrentLayer") }}
        </span>
      </v-card-title>
      <v-card-subtitle class="pb-1">
        <v-divider class="mt-2" style="background-color: black"></v-divider>
      </v-card-subtitle>
      <v-card-text style="color: black; height: 500px; " class="mb-1 pb-1">
        <template>
          <v-row v-if="metaData.EOMAP_PRODUCT != null">
            <v-col cols="3">
              {{ $t("metaData.product") }}
            </v-col>
            <v-col cols="8">
              {{ metaData.EOMAP_PRODUCT }}
            </v-col>
          </v-row>
          <v-row v-if="metaData.AREA_OR_POINT != null">
            <v-col cols="3">
              {{ $t("metaData.type") }}
            </v-col>
            <v-col cols="8">
              {{ metaData.AREA_OR_POINT }}
            </v-col>
          </v-row>
          <v-row
            v-if="
              metaData.EOMAP_ABSTRACT != null &&
                metaData.EOMAP_ABSTRACT != 'None'
            "
          >
            <v-col cols="3">
              {{ $t("metaData.abstract") }}
            </v-col>
            <v-col cols="8">
              {{ metaData.EOMAP_ABSTRACT }}
            </v-col>
          </v-row>
          <v-row v-if="metaData.EOMAP_BBOX_EAST_WGS84 != null">
            <v-col cols="3">
              {{ $t("metaData.bbox") }}
            </v-col>
            <v-col cols="8">
              [ {{ metaData.EOMAP_BBOX_EAST_WGS84 }},
              {{ metaData.EOMAP_BBOX_SOUTH_WGS84 }},
              {{ metaData.EOMAP_BBOX_WEST_WGS84 }},
              {{ metaData.EOMAP_BBOX_NORTH_WGS84 }}]
            </v-col>
          </v-row>
          <v-row v-if="metaData.EOMAP_CREATION_DATE != null">
            <v-col cols="3">
              {{ $t("metaData.creationDate") }}
            </v-col>
            <v-col cols="8">
              {{ metaData.EOMAP_CREATION_DATE }}
            </v-col>
          </v-row>
          <v-row v-if="metaData.EOMAP_EPSG != null">
            <v-col cols="3">
              {{ $t("metaData.coordinateSystem") }} (EPSG)
            </v-col>
            <v-col cols="8">
              {{ metaData.EOMAP_EPSG }}
            </v-col>
          </v-row>
          <v-row v-if="metaData.EOMAP_FORMAT != null">
            <v-col cols="3">
              {{ $t("metaData.format") }}
            </v-col>
            <v-col cols="8">
              {{ metaData.EOMAP_FORMAT }}
            </v-col>
          </v-row>
          <v-row v-if="metaData.EOMAP_SCENE_DATE_END != null">
            <v-col cols="3">
              {{ $t("metaData.scenedate") }}
            </v-col>
            <v-col cols="8">
              {{ metaData.EOMAP_SCENE_DATE_END }}
            </v-col>
          </v-row>
          <v-row v-if="metaData.EOMAP_SENSOR != null">
            <v-col cols="3">
              {{ $t("metaData.sensor") }}
            </v-col>
            <v-col cols="8">
              {{ metaData.EOMAP_SENSOR }}
            </v-col>
          </v-row>
          <v-row
            v-if="
              metaData?.TIFFTAG_XRESOLUTION ||
                metaData?.TIFFTAG_IMAGEWIDTH ||
                metaData?.TIFFTAG_YRESOLUTION ||
                metaData?.TIFFTAG_IMAGELENGTH ||
                metaData?.EOMAP_RESOLUTION
            "
          >
            <v-col cols="3">
              {{ $t("metaData.sizeAndRes") }}
            </v-col>
            <v-col cols="8">
              {{ $t("metaData.width") }}:
              <span v-if="metaData?.TIFFTAG_XRESOLUTION">
                {{ metaData.TIFFTAG_XRESOLUTION }}</span
              >
              <span v-if="metaData?.TIFFTAG_IMAGEWIDTH">
                {{ metaData.TIFFTAG_IMAGEWIDTH }}</span
              >, {{ $t("metaData.height") }}:
              <span v-if="metaData?.TIFFTAG_YRESOLUTION">
                {{ metaData.TIFFTAG_YRESOLUTION }}</span
              >
              <span v-if="metaData?.TIFFTAG_IMAGELENGTH">
                {{ metaData.TIFFTAG_IMAGELENGTH }}</span
              >,
              <span v-if="metaData.EOMAP_RESOLUTION != null"
                >{{ $t("metaData.resolution") }}:
                {{ metaData.EOMAP_RESOLUTION }} m</span
              >
            </v-col>
          </v-row>
          <v-row v-if="metaData.EOMAP_PROCESSOR_VERSION != null">
            <v-col cols="3">
              {{ $t("metaData.processorVersion") }}
            </v-col>
            <v-col cols="8">
              {{ metaData.EOMAP_PROCESSOR_VERSION }}
            </v-col>
          </v-row>
          <v-row v-if="metaData.TIFFTAG_COPYRIGHT != null">
            <v-col cols="3">
              {{ $t("metaData.copyright") }}
            </v-col>
            <v-col cols="8">
              <v-row>
                <v-col>
                  {{ metaData.TIFFTAG_COPYRIGHT }}
                </v-col>
              </v-row>
              <v-row v-if="metaData.EOMAP_SENSOR != null">
                <v-col v-if="metaData.EOMAP_SENSOR.includes('Landsat')">
                  {{ metaData.EOMAP_SENSOR }} image courtesy of the U.S.
                  Geological Survey
                </v-col>
                <v-col v-if="metaData.EOMAP_SENSOR.includes('Sentinel')">
                  Contains Copernicus data ({{ getSceneYear }})
                </v-col>
                <v-col v-if="metaData.EOMAP_SENSOR.includes('Superdove')">
                  Includes material © ({{ getSceneYear }}) Planet Labs Germany
                  GmbH. All rights reserved
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row v-if="metaData.EOMAP_LEGAL != null">
            <v-col cols="3">
              {{ $t("metaData.legal") }}
            </v-col>
            <v-col cols="8">
              {{ metaData.EOMAP_LEGAL }}
            </v-col>
          </v-row>
        </template>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "MetadataPopup",
  data() {
    return {
      dialog: false,
      metaData: {}
    };
  },
  computed: {
    ...mapState("raster", ["showMetaDataWindow", "activeRasterLayer"]),
    ...mapState("management", ["accessToken"]),
    getSceneYear() {
      return this.metaData.EOMAP_SCENE_DATE_END.split("-")[0];
    }
  },

  methods: {
    async fetchMetadata() {
      const response = await this.$rastless.get(
        `/layers/${this.activeRasterLayer.layerId}/${this.activeRasterLayer.datetime}/metadata?token=${this.accessToken}`
      );
      return response.data.data[0];
    }
  },
  watch: {
    async activeRasterLayer() {
      this.metaData = await this.fetchMetadata();
    }
  },
  async created() {
    this.metaData = await this.fetchMetadata();
  }
};
</script>

<style></style>
