<template>
  <div
    style="background-color: rgba(0,0,0,0)"
    class=" map-overlay-inner pt-8 pl-15 pr-10 pb-6"
  >
    <label class="font-weight-bold ml-2">{{ activeRasterLayer.title }}</label>
    <br />
    <label class=" ml-2">{{
      reverseLayerTimesteps[selectedTab].datetime
    }}</label>
    <v-slider
      v-model="selectedTab"
      color="#40ADD5"
      :max="reverseLayerTimesteps.length - 1"
      step="1"
      track-color="primary"
      ticks="always"
      tick-size="4"
    >
      <template v-slot:append v-if="playOption">
        <v-btn
          v-if="!isPlaying"
          color="primary"
          dark
          fab
          small
          @click="togglePlay"
        >
          <v-icon large>
            mdi-play
          </v-icon>
        </v-btn>
        <v-btn
          v-if="isPlaying"
          color="primary"
          dark
          fab
          small
          @click="isPlaying = false"
        >
          <v-icon large>
            mdi-pause
          </v-icon>
        </v-btn>
      </template>
    </v-slider>
  </div>
</template>

<script>
export default {
  name: "TimelabSlider",
  props: {
    activeRasterLayer: Object,
    sortlayerTimesteps: Array,
    playOption: { Boolean, default: false }
  },
  data: () => ({
    isPlaying: false,
    selectedTab: 0
  }),
  computed: {
    reverseLayerTimesteps() {
      let array = [...this.sortlayerTimesteps];
      array.reverse();
      return array;
    }
  },
  methods: {
    togglePlay() {
      this.isPlaying = true;
      this.$emit("setPlayStatus");
      let startindex = 0;
      if (this.savedStep) {
        startindex = this.savedStep;
      }

      for (let i = startindex; i <= this.sortlayerTimesteps.length - 1; i++) {
        this.mytimeout = setTimeout(() => {
          if (this.isPlaying) {
            this.selectedDatetime = this.sortlayerTimesteps[i];
            this.selectedTab = i;
            this.savedStep = i;
            if (i === this.sortlayerTimesteps.length - 1) {
              this.isPlaying = false;
              this.savedStep = null;
            }
          } else {
            clearTimeout(this.mytimeout);
          }
        }, (i + 1) * 1500);
      }
    }
  },
  watch: {
    selectedTab() {
      let tab = this.reverseLayerTimesteps.length - 1 - this.selectedTab;
      this.$emit("changeTimeStep", tab);
    }
  }
};
</script>

<style scoped>
.map-overlay .map-overlay-inner {
  background-color: #fff;
  //box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2); //border-radius: 3px; //padding: 10px; //margin-bottom: 10px;
}

.map-overlay h2 {
  line-height: 25px;
  display: block;
  margin: 0 0 10px;
}

.map-overlay .legend .bar {
  height: 10px;
  width: 100%;
  background: linear-gradient(to right, #fca107, #7f3121);
}

.map-overlay input {
  background-color: transparent;
  display: inline-block;
  width: 100%;
  position: relative;
  margin: 0;
  cursor: ew-resize;
}
</style>
