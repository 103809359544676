<template>
  <div>
    <v-card class=" card-dialog">
      <v-row>
        <v-col cols="auto" class="pr-0">
          <v-checkbox hide-details class=" pl-2 ml-1" v-model="showLayer">
          </v-checkbox>
        </v-col>
        <v-col class="pl-0">
          <v-autocomplete
            v-if="virtualStations.length"
            ref="autocomplete"
            auto-select-first
            clearable
            dense
            class="pa-2 pt-4 px-5 pl-0"
            hide-details
            hide-selected
            hide-no-data
            item-text="name"
            :label="$t('station')"
            v-model="localSelectedStation"
            return-object
            :items="virtualStations"
          >
            <template #item="{ item }">
              <v-list-item class="d-flex">
                <div @click="toggleItem(item)" style="width: 180px">
                  {{ item.name }}
                </div>
                <div>
                  <actions-menu :option="'small'" :item="item"></actions-menu>
                </div>
              </v-list-item>
            </template>
          </v-autocomplete>
          <div v-else>
            <span class="ml-10 " style="color: #1D4180; font-size: large">{{
              $t("drawFirstStation")
            }}</span>
          </div>
        </v-col>
      </v-row>

      <!--      <show-virtual-station-option :virtual-stations="virtualStations"></show-virtual-station-option>-->

      <draw-station
        :map="map"
        @toggleSnackbar="toggleSnackbar"
        @changeMode="emitChangeMode"
      ></draw-station>
    </v-card>
    <v-snackbar v-model="drawSnackbar" absolute timeout="-1" outlined>
      <v-icon color="primary">mdi-information-outline</v-icon>
      {{ snackbarText }}
    </v-snackbar>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import store from "@/core/store";
import bbox from "@turf/bbox";
import circle from "@turf/circle";
import virtualStationMixin from "@/core/mixins/virtualStation.mixin";
import DrawStation from "@/core/components/map/RightSideMenu/DrawStation.vue";
import ActionsMenu from "@/core/components/map/virtualStationEntry/ActionsMenu.vue";

export default {
  name: "AnalysisStation",
  components: { ActionsMenu, DrawStation },
  mixins: [virtualStationMixin],
  props: {
    map: null,
    place: String
  },
  data: () => ({
    showLayer: true,
    page: 1,
    pageCount: 0,
    menuOpened: false,
    localSelectedStation: null,
    drawSnackbar: false,
    snackbarText: ""
  }),
  computed: {
    ...mapState("draw", ["selectedFeatureId"]),
    ...mapState("plot", ["lineStation", "selectedStation"]),
    ...mapGetters("draw", ["selectedFeature"]),
    ...mapGetters("management", ["activeRegion"]),
    ...mapState("draw", ["virtualStations"]),
    headers() {
      return [
        {
          text: "",
          align: "start",
          sortable: false,
          value: "type"
        },
        {
          text: this.$t("station"),
          sortable: true,
          value: "name"
        },
        { text: this.$t(""), value: "actions", sortable: false }
      ];
    },
    showPagination() {
      return this.pageCount > 1;
    }
  },
  methods: {
    ...mapActions("app", ["showSnackbar"]),
    ...mapActions("plot", [
      "setStation",
      "setShowTransectPlot",
      "setLineStation",
      "setShowPlot",
      "setShowPlotFeature"
    ]),
    ...mapActions("draw", [
      "setSelectedFeatureId",
      "deleteVirtualStation",
      "updateName",
      "setVSPermanent",
      "addPermanentVirtualStations",
      "addVirtualStation"
    ]),
    ...mapActions("plot", ["resetDataCache"]),
    ...mapActions("app", ["showSnackbar"]),
    sortName(stations) {
      const temStation = [...stations];
      return temStation.sort((a, b) =>
        a.name.toUpperCase().localeCompare(b.name.toUpperCase())
      );
    },
    emitChangeMode(val) {
      this.$emit("changeMode", val);
    },
    toggleSnackbar(text) {
      if (text) {
        this.drawSnackbar = true;
        this.snackbarText = text;
      } else {
        this.drawSnackbar = false;
      }
    },
    showDrawSnackbar(text) {
      this.drawSnackbar = true;
      this.snackbarText = text;
    },
    zoomToVirtualStation(item) {
      const mapItem = store.draw.get(item.id);
      const geojson = mapItem.geometry;

      let geojson_bbox = bbox(geojson);
      if (mapItem.geometry.type === "Point") {
        const myCircle = circle(geojson, 0.1, {
          steps: 10,
          units: "kilometers"
        });
        geojson_bbox = bbox(myCircle);
      }
      this.$store.visualizeMap.fitBounds(geojson_bbox, { padding: 100 });
    },
    toggleItem(item) {
      this.localSelectedStation = item;
      this.$refs.autocomplete.isMenuActive = false;
    }
  },
  watch: {
    showLayer(val) {
      if (val) {
        this.$store.visualizeMap.U.showSource([
          "mapbox-gl-draw-cold",
          "mapbox-gl-draw-hot"
        ]);
      } else {
        this.$store.visualizeMap.U.hideSource([
          "mapbox-gl-draw-cold",
          "mapbox-gl-draw-hot"
        ]);
      }
    },
    localSelectedStation() {
      // this.resetDataCache();
      if (this.localSelectedStation) {
        this.zoomToVirtualStation(this.localSelectedStation);
        if (this.localSelectedStation.geometry.type == "LineString") {
          this.setShowTransectPlot(true);
          this.setLineStation(this.localSelectedStation);
        } else {
          // this.setShowPlotFeature(true);
          this.setStation(this.localSelectedStation);
          this.addTimeseriesToPlot();
        }
      }
    },
    selectedStation() {
      if (!this.selectedStation) {
        this.localSelectedStation = undefined;
      }
    },
    lineStation() {
      if (!this.lineStation && !this.selectedStation) {
        this.localSelectedStation = undefined;
      }
    }
  }
};
</script>

<style scoped>
#analysis-switch {
  margin-top: 40px;
}

.card-dialog {
  position: absolute;
  right: 65px;
  top: 20px;
  z-index: 2;
}

.station-dialog {
  max-width: 300px;
  position: absolute;
  right: 65px;
  top: 70px;
  z-index: 2;
}

.mapboxgl-ctrl-logo {
  display: none !important;
}
</style>
