<template>
  <div>
    <!--  <v-menu bottom :close-on-content-click="false" :close-on-click="false" offset-x>-->
    <!--    <template v-slot:activator="{ on }">-->
    <!--      <v-icon-->
    <!--        v-on="on"-->
    <!--        v-if="$keycloak.authenticated"-->
    <!--        :title="$t('saveVirtualStation')"-->
    <!--        small-->
    <!--        class="mr-2"-->
    <!--      >-->
    <!--        mdi-dots-vertical-->
    <!--      </v-icon>-->
    <!--    </template>-->
    <!--    <v-icon-->
    <!--      v-if="$keycloak.authenticated"-->
    <!--      :title="$t('saveVirtualStation')"-->
    <!--      small-->
    <!--      class="mr-2"-->
    <!--      @click.stop="saveItem(item)"-->
    <!--      :color="saveButtonColor(item)"-->
    <!--    >-->
    <!--      mdi-content-save-->
    <!--    </v-icon>-->
    <rename-virtual-station :item="item" @save-item="saveItem" />
    <delete-station-menu :item="item" @delete-station="deleteItem" />
    <v-icon
      v-if="$keycloak.authenticated"
      :title="$t('downloadGeometry')"
      small
      class="mr-1"
      @click.prevent="downloadGeometry(item)"
    >
      mdi-download
    </v-icon>
    <v-icon
      v-if="option === 'full'"
      :title="$t('zoomToVirtualStation')"
      small
      class="mr-2"
      @click.stop="zoomToVirtualStation(item)"
    >
      mdi-magnify-plus
    </v-icon>
  </div>
  <!--  </v-menu>-->
</template>

<script>
import DeleteStationMenu from "@/core/components/menu/eotool/visualize/virtual-station-layer-entry/DeleteVirtualStation.vue";
import { mapActions, mapGetters, mapState } from "vuex";
import store from "@/core/store";
import bbox from "@turf/bbox";
import circle from "@turf/circle";
import virtualStation from "@/core/services/virtualStation.services";
import axios from "axios";
import RenameVirtualStation from "@/core/components/menu/eotool/visualize/virtual-station-layer-entry/RenameVirtualStation.vue";

export default {
  name: "ActionsMenu",
  components: { RenameVirtualStation, DeleteStationMenu },
  props: {
    item: Object,
    option: { type: String, default: "full" }
  },
  data: function() {
    return {
      showMenu: false,
      name: this.item.name
    };
  },
  computed: {
    ...mapState("draw", ["selectedFeatureId"]),
    ...mapGetters("draw", ["selectedFeature"]),
    ...mapGetters("management", ["activeRegion"]),
    headers() {
      return [
        {
          text: "",
          align: "start",
          sortable: false,
          value: "type"
        },
        {
          text: this.$t("station"),
          sortable: true,
          value: "name"
        },
        { text: this.$t("actions"), value: "actions", sortable: false }
      ];
    },
    showPagination() {
      return this.pageCount > 1;
    }
  },
  methods: {
    ...mapActions("app", ["showSnackbar"]),
    ...mapActions("draw", [
      "setSelectedFeatureId",
      "deleteVirtualStation",
      "updateName",
      "setVSPermanent",
      "addPermanentVirtualStations",
      "addVirtualStation"
    ]),
    zoomToVirtualStation(item) {
      const mapItem = store.draw.get(item.id);
      const geojson = mapItem.geometry;

      let geojson_bbox = bbox(geojson);
      if (mapItem.geometry.type === "Point") {
        const myCircle = circle(geojson, 0.1, {
          steps: 10,
          units: "kilometers"
        });
        geojson_bbox = bbox(myCircle);
      }
      this.$store.visualizeMap.resize();
      this.$store.visualizeMap.fitBounds(geojson_bbox, { padding: 100 });
    },
    saveItem(item) {
      if (item.permanent) {
        try {
          virtualStation.update(item.id);
          this.showSnackbar({
            show: true,
            message: this.$t("stationUpdated"),
            color: "success"
          });
        } catch (error) {
          this.showSnackbar({
            show: true,
            message: this.$t("errorMessage"),
            color: "error"
          });
        }
      } else {
        try {
          virtualStation.save(item.id);
          this.setVSPermanent(item.id);
          this.showSnackbar({
            show: true,
            message: this.$t("stationSaved"),
            color: "success"
          });
        } catch (error) {
          this.showSnackbar({
            show: true,
            message: this.$t("errorMessage"),
            color: "error"
          });
        }
      }
    },
    deleteItem(item) {
      if (item.permanent) {
        try {
          virtualStation.remove(item.id);
          this.showSnackbar({
            show: true,
            message: this.$t("stationDeleted"),
            color: "success"
          });
        } catch (error) {
          this.showSnackbar({
            show: true,
            message: this.$t("errorMessage"),
            color: "error"
          });
        }
      }
      this.deleteVirtualStation(item.id);
      this.$store.draw.delete(item.id);
      if (this.selectedFeatureId === item.id) {
        this.setSelectedFeatureId(null);
        this.$store.draw.changeMode("simple_select");
      }
      this.$emit("close-plot");
    },
    saveButtonColor(item) {
      return item.permanent === true ? "green" : "darkgrey";
    },
    async downloadGeometry(item) {
      await axios
        .get(`/userdata/virtual-stations/${item.id}/geometry`, {
          responseType: "application/json"
        })
        .then(response => {
          let geojson = {
            type: "FeatureCollection",
            features: [
              {
                type: "Feature",
                properties: {},
                geometry: {}
              }
            ]
          };
          geojson.features[0].geometry = response.data;
          geojson.features[0].properties.name = item.name;
          var jsonse = JSON.stringify(geojson, null, 2);
          const blob = new Blob([jsonse], { type: "application/json" });
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement("a");
          a.href = url;
          a.download = item.name + ".json";
          a.click();
        });
      this.showSnackbar({
        show: true,
        message: this.$t("virtualStationDownloaded"),
        color: "success"
      });
    },
    vsIcon(type) {
      let icon = "";
      switch (type) {
        case "LineString":
          icon = "mdi-vector-polyline";
          break;
        case "Polygon":
          icon = "mdi-vector-polygon";
          break;
        default:
          icon = "mdi-vector-point";
          break;
      }
      return icon;
    }
  },
  watch: {
    showLayer(val) {
      if (val) {
        this.$store.visualizeMap.U.showSource([
          "mapbox-gl-draw-cold",
          "mapbox-gl-draw-hot"
        ]);
      } else {
        this.$store.visualizeMap.U.hideSource([
          "mapbox-gl-draw-cold",
          "mapbox-gl-draw-hot"
        ]);
      }
    }
  }
};
</script>

<style scoped></style>
