<template>
  <div style="width: 50%" class="mt-16">
    <region-menu-header
      name="Sediment"
      :icon="'mdi-water-percent'"
      :disable-region-menu="true"
      :disable-data-request="true"
    />
    <v-divider />
    <div class="mt-5 ml-10">
      <SedimentTool></SedimentTool>
    </div>
  </div>
</template>

<script>
import RegionMenuHeader from "@/core/components/menu/RegionMenuHeader";
import SedimentTool from "@/core/components/menu/eotool/analyse/SedimentTool";

export default {
  name: "SedimentMenu",
  components: {
    RegionMenuHeader,
    SedimentTool: SedimentTool //() => import("./analyse/SedimentTool"),
  },
  data: () => ({
    activeComponents: []
  })
};
</script>

<style scoped></style>
