<template>
  <v-list class="grow" nav dense>
    <v-list-item style="height: 20px">
      <v-list-item-avatar class="mr-0">
        <v-icon>{{ icon }}</v-icon>
      </v-list-item-avatar>
      <v-list-item-title class="subtitle-1">
        {{ $t(name) }}
      </v-list-item-title>
    </v-list-item>
    <region-selection-small
      class="ml-3 mb-4"
      :disable-region-menu="disableRegionMenu"
    />
    <request-data-for-region />
  </v-list>
</template>

<script>
import RegionSelectionSmall from "@/core/components/menu/RegionSelectionSmall";
import RequestDataForRegion from "@/core/components/menu/eotool/RequestDataForRegion.vue";

export default {
  name: "RegionMenuHeader",
  components: { RequestDataForRegion, RegionSelectionSmall },
  props: {
    name: String,
    icon: String,
    disableRegionMenu: {
      type: Boolean,
      default: false
    },
    disableDataRequest: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style scoped></style>
