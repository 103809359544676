<template>
  <div class="pl-2 pb-2">
    <vs-info />
    <span class="font-weight-bold" style="align-self: end"
      >{{ $t("addStation") }}:</span
    >
    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          icon
          elevation="0"
          v-bind="attrs"
          v-on="on"
          @click.stop="drawPolygon"
        >
          <v-icon color="primary">mdi-vector-polygon</v-icon>
        </v-btn>
      </template>
      <span>{{ $t("addVSPolygon") }}</span>
    </v-tooltip>
    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          elevation="0"
          icon
          v-bind="attrs"
          v-on="on"
          @click.stop="drawPoint"
        >
          <v-icon color="primary">mdi-vector-point</v-icon>
        </v-btn>
      </template>
      <span>{{ $t("addVSPoint") }}</span>
    </v-tooltip>
    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="ml-2"
          elevation="0"
          icon
          v-bind="attrs"
          v-on="on"
          @click.stop="drawLine"
        >
          <v-icon color="primary">mdi-vector-polyline</v-icon>
        </v-btn>
      </template>
      <span>{{ $t("addVSLine") }}</span>
    </v-tooltip>

    <upload-virtual-station></upload-virtual-station>
    <v-divider vertical class="px-2"></v-divider>
    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          icon
          elevation="0"
          v-bind="attrs"
          class="mr-2"
          v-on="on"
          @click.stop="toggleEditMode"
        >
          <v-icon v-if="layerEditMode">mdi-close</v-icon>
          <v-icon v-else> mdi-vector-polyline-edit</v-icon>
        </v-btn>
      </template>
      <span v-if="layerEditMode">{{ $t("cancel") }}</span>
      <span v-else>{{ $t("vectorEditMode") }}</span>
    </v-tooltip>
  </div>
</template>

<script>
import popUpMixin from "@/core/mixins/popUp.mixin";
import { mapActions, mapGetters, mapState } from "vuex";
import VsInfo from "@/core/components/infos/VsInfo.vue";
import UploadVirtualStation from "@/core/components/menu/eotool/visualize/virtual-station-layer-entry/UploadVirtualStation.vue";

export default {
  name: "DrawStation",
  components: { UploadVirtualStation, VsInfo },
  props: ["map", "layers"],
  mixins: [popUpMixin],
  data: () => ({
    fab: false,
    selectedGeometry: null,
    layerEditMode: false,
    openMenu: false
  }),
  computed: {
    ...mapState("draw", ["selectedFeatureId"]),
    ...mapGetters("draw", ["stationById"]),
    buttonColor() {
      return this.fab ? "primary" : "accent darken-2";
    },
    layerEditButton() {
      return this.layerEditMode ? "primary" : "accent darken-2";
    }
  },
  methods: {
    ...mapActions("draw", ["setSelectedFeatureId", "addVirtualStation"]),
    drawFeature(mode, message) {
      this.layerEditMode = true;
      this.$store.draw.changeMode(mode);
      this.$emit("toggleSnackbar", this.$t(message));
      setTimeout(() => {
        this.$emit("toggleSnackbar", "");
      }, 6000);
    },
    drawPoint() {
      this.drawFeature("draw_point", "snackInfoPoint");
    },
    drawPolygon() {
      this.drawFeature("draw_polygon", "snackInfoPolygon");
    },
    drawLine() {
      this.drawFeature("draw_line_string", "snackInfoLine");
    },
    toggleEditMode() {
      if (this.layerEditMode) {
        this.$store.draw.changeMode("static");
        this.$emit("toggleSnackbar");
      } else {
        this.$store.draw.changeMode("simple_select");
      }
      this.layerEditMode = !this.layerEditMode;
    },
    resetEditModeAfterDrawCreated() {
      this.map.on("draw.create", () => {
        setTimeout(() => {
          this.layerEditMode = false;
          this.$store.draw.changeMode("static");
        }, 100);
      });
    }
  },
  created() {
    this.resetEditModeAfterDrawCreated();
  },
  watch: {
    layerEditMode(val) {
      this.$emit("changeMode", val);
    }
  }
};
</script>

<style scoped>
#analysis-switch {
  margin-top: 90px;
}
</style>

<style>
.mapboxgl-ctrl-logo {
  display: none !important;
}
</style>
